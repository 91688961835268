import React from "react";
import {PostContainer, TemplateHeader} from "./styles";
import Template1 from "../templates/Template1";
import Template2 from "../templates/Template2";
import Template3 from "../templates/Template3";
import {Carousel} from "react-responsive-carousel";
import PropTypes from "prop-types";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/pro-light-svg-icons";

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  width: 30,
  height: 30,
  cursor: "pointer",
  // change the color and size as needed
};

const ArrowLeft = ({onClickHandler}) => (
  <div style={{...arrowStyles, left: 15, top: 0, zIndex: 100}}>
    <FontAwesomeIcon icon={faArrowLeft} onClick={onClickHandler} color="black" size="2x" />
  </div>
);

const ArrowRight = ({onClickHandler}) => (
  <div style={{...arrowStyles, right: 15, top: 0}}>
    <FontAwesomeIcon icon={faArrowRight} onClick={onClickHandler} size="2x" color="black" />
  </div>
);

const Preview = ({data, canUsePremiumTemplates, handleTemplateChange}) => {
  const onSlideChange = (index) => {
    handleTemplateChange(index+1, "templateId");
  };

  if (!data?.vehicleData) {
    return (
      <div>
                Loading {data?.state}
      </div>
    );
  }


  return (
    <PostContainer>
      <Carousel
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && <ArrowLeft onClickHandler={onClickHandler} />
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && <ArrowRight onClickHandler={onClickHandler} />
        }
        onChange={onSlideChange}
      >
        <div>
          <TemplateHeader>
            <h2>Template 1</h2>
          </TemplateHeader>
          <Template1
            mainImage={data?.vehicleData?.mainImage}
            make={data?.vehicleData?.make}
            model={data?.vehicleData?.model}
            price={data?.vehicleData?.price}
            vin={data?.vehicleData?.vin}
            year={data?.vehicleData?.year}
            odometer={data?.vehicleData?.mileage}
            stockNumber={data?.vehicleData?.stockNumber}
            primaryColor={data?.template?.color}
          />
        </div>
        <div>
          <TemplateHeader>
            <h2>Template 2</h2>
          </TemplateHeader>
          <Template2
            mainImage={data?.vehicleData?.mainImage}
            make={data?.vehicleData?.make}
            model={data?.vehicleData?.model}
            price={data?.vehicleData?.price}
            vin={data?.vehicleData?.vin}
            year={data?.vehicleData?.year}
            odometer={data?.vehicleData?.mileage}
            stockNumber={data?.vehicleData?.stockNumber}
            financing={data?.financing}
            primaryColor={data?.template?.color}
          />
        </div>

        <div>
          <TemplateHeader>
            <h2>Template 3</h2>
          </TemplateHeader>
          <Template3
            mainImage={data?.vehicleData?.mainImage}
            make={data?.vehicleData?.make}
            model={data?.vehicleData?.model}
            price={data?.vehicleData?.price}
            vin={data?.vehicleData?.vin}
            year={data?.vehicleData?.year}
            odometer={data?.vehicleData?.mileage}
            stockNumber={data?.vehicleData?.stockNumber}
            financing={data?.financing}
            primaryColor={data?.template?.color}
          />
        </div>

      </Carousel>
    </PostContainer>
  );
};

ArrowLeft.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
};

ArrowRight.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
};

Preview.propTypes = {
  data: PropTypes.shape({
    vehicleData: PropTypes.shape({
      mainImage: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      price: PropTypes.string,
      vin: PropTypes.string,
      year: PropTypes.string,
      mileage: PropTypes.string,
      stockNumber: PropTypes.string,
    }),
    state: PropTypes.string,
    template: PropTypes.shape({
      color: PropTypes.string,
    }),
    financing: PropTypes.bool,
  }),
  canUsePremiumTemplates: PropTypes.bool,
  handleTemplateChange: PropTypes.func.isRequired,
};

export default Preview;
