import React from "react";
import {Wrapper, FeatureText, FeatureTitle} from "./styles";
import {Container, Grid, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";


const Feature1 = () => {
  return (
    <Wrapper>
      <Container maxWidth="lg">
        <Grid container md={12}>
          <Grid item justify="center" xs={12} md={4} >
            <FeatureTitle>Effortless Content Generation</FeatureTitle>
            <FeatureText>
              By simply pasting a link to the vehicle information page,
              Auto Influence generates custom, engaging social media posts,
              eliminating the time and effort typically required for content creation.
            </FeatureText>
          </Grid>
          {/* <Divider orientation="vertical" flexItem />*/}
          <Grid item disableGutters justifyContent="center" alignItems="center" xs={12} md={4}>
            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon size="lg" icon={faCheck} color="green" />
                </ListItemIcon>
                <ListItemText
                  primary="Affordable Pricing for all Dealership sizes"
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon size="lg" icon={faCheck} color="green" />
                </ListItemIcon>
                <ListItemText
                  primary="Say goodbye to writer's block. Just paste a link to a vehicle."
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon size="lg" icon={faCheck} color="green" />
                </ListItemIcon>
                <ListItemText
                  primary="Generate engaging posts in a couple of clicks."
                />
              </ListItem>
            </List>

          </Grid>

          <Grid item xs={12} md={4} disableGutters>
            <FeatureTitle>Dynamic Tone</FeatureTitle>
            <FeatureText>
                        Auto Influence adjusts its writing style to align with
              your brand&apos;s personality. Whether you prefer a formal,
              casual, or witty tone, the app tailors the content to meet your needs.
            </FeatureText>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Feature1;
