import React, {useEffect, useState} from "react";
import Template1 from "../templates/Template1";
import Template2 from "../templates/Template2";
import Template3 from "../templates/Template3";
import {useSearchParams} from "react-router-dom";
import {doc, getFirestore, getDoc} from "firebase/firestore";
import {app} from "../../firebase/clientApp";

/**
 * PostPreview component.
 *
 * @component
 * @return {JSX.Element | null} PostPreview component.
 */
function PostPreview() {
  try {
    const [searchParams] = useSearchParams();
    const uid = searchParams.get("uid");
    const postId = searchParams.get("postId");
    const [data, setData] = useState(null);
    // const [financing, setFinancing] = useState(false);
    const [template, setTemplate] = useState(null);

    const db = getFirestore(app);

    const getData = async () => {
      const userRef = doc(db, "users", uid);
      const docRef = doc(userRef, "posts", postId);
      const res = await getDoc(docRef);
      if (res.data()) {
        const {vehicleData, template} = res.data();
        setData(vehicleData);
        // setFinancing(financing);
        // contains template.id = 1/2/3
        setTemplate(template);
      }
    };

    useEffect(() => {
      getData();
    }, []);

    if (!template || !data) {
      return null;
    }

    const templates = {
      1: Template1,
      2: Template2,
      3: Template3,
    };

    const TemplateComponent = templates[template.templateId];

    return (
      <div style={{width: 512, height: 512}}>
        <TemplateComponent
          mainImage={data?.mainImage}
          make={data?.make}
          model={data?.model}
          price={data?.price}
          vin={data?.vin}
          year={data?.year}
          odometer={data?.mileage}
          stockNumber={data?.stockNumber}
          primaryColor={template?.color}
          financing={data?.financing}
        />
      </div>
    );
  } catch (error) {
    console.log(error);
  }
}

export default PostPreview;
