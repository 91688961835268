import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {AuthProvider} from "./context/auth-context";
import {SubscriptionProvider} from "./context/subscription-context";
import {GlobalStateProvider} from "./context/state-context";

import Landing from "./components/Landing";
import Login from "./pages/Auth/Login";
import EmailAuth from "./pages/Auth/Email";
import VerifyEmail from "./pages/Auth/Verify-Email";

import Create from "pages/Create";
import VehiclePost from "./pages/Create/VehiclePost";
import EditPost from "./pages/Posts/Edit";
import ChatBot from "./pages/ChatBot";
import UserPosts from "./pages/Posts";
import PostPreview from "components/PostPreview";
import View from "./pages/Posts/View";
import PrivateRoute from "components/PrivateRoute";
import {CssBaseline} from "@mui/material";
import DealershipList from "./components/Dashboard/Dealerships";
import DealerForm from "./components/Dashboard/Dealerships/DealerForm";
import Billing from "./components/Dashboard/Billing";
import BillingSuccess from "./components/Dashboard/Billing/success";

import Terms from "./components/common/Footer/Screens/Terms";
import Privacy from "./components/common/Footer/Screens/Privacy";
import About from "./components/common/Footer/Screens/About";
import FAQ from "./components/common/Footer/Screens/FAQ";
import Contact from "./components/common/Footer/Screens/Contact";
/** Manager screens */
import CreateDemo from "./pages/Manager/Demos/Create";
import ManagerPostPreview from "./components/Manager/Preview/index";

import {recordScreenView} from "./classes/analytics";

const {palette} = createTheme();
const {augmentColor} = palette;
const createColor = (mainColor) => augmentColor({color: {main: mainColor}});

const theme = createTheme({
  palette: {
    googleBlue: createColor("#3f7fe9"),
    primary: {
      // light: will be calculated from palette.primary.main,
      // main: '#1B9AAA',
      main: "#1b145d",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#1B9AAA",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#1B9AAA",
    },
  },
  typography: {
    fontFamily: [
      "Plus Jakarta Sans",
      "ui-sans-serif",
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Roboto",
      "\"Helvetica Neue\"",
      "Arial",
      "sans-serif",
      "\"Apple Color Emoji\"",
      "\"Segoe UI Emoji\"",
      "\"Segoe UI Symbol\"",
    ].join(","),
  },
});

theme.typography.h3 = {
  fontSize: "1.8rem",
  lineHeight: "2.2rem",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
    lineHeight: "2.9rem",
  },
};

theme.typography.body1 = {
  textAlign: "center",
  [theme.breakpoints.up("md")]: {

  },
};

// eslint-disable-next-line valid-jsdoc
/**
 * Custom Hook for recording screen views with Firebase Analytics
 *
 * @function FirebaseAnalytics
 * @requires firebase/analytics
 */
function FirebaseAnalytics() {
  const location = useLocation();
  useEffect(() => {
    recordScreenView(location.pathname + location.search);
  }, [location]);
  return null;
}

// eslint-disable-next-line valid-jsdoc
/**
 * Custom Hook to handle scroll to top when changing routes
 *
 * @function ScrollToTop
 * @requires react-router-dom
 */
function ScrollToTop() {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// eslint-disable-next-line valid-jsdoc
/**
 * Main App component which wraps all routes and global providers
 *
 * @component
 * @example
 * return (<App />)
 */
function App() {
  return (
    <div className="App">
      <CssBaseline />
      <AuthProvider>
        <SubscriptionProvider>
          <GlobalStateProvider>
            <ThemeProvider theme={theme}>
              <Router>
                <FirebaseAnalytics />
                <ScrollToTop />
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/auth/login" element={<Login />} />
                  <Route path="/auth/email" element={<EmailAuth />} />
                  <Route path="/auth/verify-email" element={<VerifyEmail />} />
                  <Route path="/post" element={<PostPreview />} />
                  <Route path="/terms-of-use" element={<Terms />} />
                  <Route path="/privacy-policy" element={<Privacy />} />
                  <Route path="/privacy-policy" element={<Privacy />} />
                  <Route path="/about-us" element={<About />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/contact-us" element={<Contact />} />

                  <Route
                    path="/create"
                    element={
                      <PrivateRoute path="/create">
                        <Create />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/create/vehicle"
                    element={
                      <PrivateRoute path="/create/vehicle">
                        <VehiclePost />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/create/marketing"
                    element={
                      <PrivateRoute path="/create/marketing">
                        <ChatBot />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/posts"
                    element={
                      <PrivateRoute>
                        <UserPosts />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/posts/edit/:jobId"
                    element={
                      <PrivateRoute>
                        <EditPost />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/posts/view"
                    element={
                      <PrivateRoute>
                        <View />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dealerships"
                    element={
                      <PrivateRoute>
                        <DealershipList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dealerships/dealer"
                    element={
                      <PrivateRoute>
                        <DealerForm />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/billing"
                    element={
                      <PrivateRoute>
                        <Billing />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/billing/success"
                    element={
                      <PrivateRoute>
                        <BillingSuccess />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/manager/create/demo"
                    element={
                      <PrivateRoute>
                        <CreateDemo />
                      </PrivateRoute>
                    }
                  />

                  <Route path="/manager/preview" element={<ManagerPostPreview />} />

                </Routes>
              </Router>
            </ThemeProvider>
          </GlobalStateProvider>
        </SubscriptionProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
