import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Wrapper = styled.div`
  text-align: center
`;

export const Icon = styled(FontAwesomeIcon)`
  color: #ffa100;
  font-size: 6rem;
`;
