import styled from "styled-components";
import {Card, Paper} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
export const StyledCard = styled(Card)`
  margin: 20px;
`;

export const NoPostsWrapper = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  flex-direction: column;
`;

export const NoPostsIcon = styled(FontAwesomeIcon)`
  font-size: 68px;
  margin-bottom: 25px;
`;

export const NoPostsText = styled.h3`
  font-weight: normal;
  font-size: 35px;
`;

export const StyledPaper = styled(Paper)`
  background-color: #f9f9f9;
`;
