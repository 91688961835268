import React, {useState, useRef, useEffect} from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Paper,
  Grid,
  Chip,
  ButtonGroup,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload, faEdit, faFloppyDisk, faRotateRight} from "@fortawesome/pro-light-svg-icons";
import useRateLimit from "../../../hooks/useRateLimit";
import {useNavigate, useParams} from "react-router-dom";
import {SketchPicker} from "react-color";
import Images from "../../../components/Images";
import Preview from "../../../components/Preview";
import PostLimit from "../../../components/PostLimit";
import CommonContainer from "../../../components/common/Layout/Container";
import {
  PostWrapper,
  PostBody,
  PostText,
  ColorPickerContainer,
  Palette,
  GeneratingPost,
  TitleHeader,
  TitleText,
  TitleActions,
  PostAction,
  EditIcons,
} from "./styles";

import useCompletionStream from "../../../hooks/useCompletionStream";
import Loading from "../../../components/common/Loading";
import {doc, getFirestore, onSnapshot, updateDoc} from "firebase/firestore";
import errorhandler from "../../../classes/errorhandler";
import {httpsCallable} from "firebase/functions";
import {app, functions} from "../../../firebase/clientApp";
import {recordEvent} from "../../../classes/analytics";
import {useGlobalState} from "../../../context/state-context";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import TemperatureDialog from "../../../components/common/TemperatureDialog";
import TabPanel from "components/common/TabPanel";
import LoadingSequence from "../../../components/LoadingSequence";
import {useAuth} from "../../../context/auth-context";

/**
 * EditPost is a React component that allows the user to edit a post.
 * It provides fields for editing the text and vehicle data of the post, and buttons for saving changes and publishing the post.
 * It also provides a color picker for changing the color of the post template, and a dialog for confirming the publish action.
 *
 * @component
 * @example
 * // Render the EditPost component
 * <EditPost />
 *
 * @return {React.Element} The rendered EditPost component
 *
 * @module components/EditPost
 */
function EditPost() {
  const {jobId} = useParams();
  const {user} = useAuth();
  const db = getFirestore(app);
  const [data, setData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [editable, setEditable] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showTempDialog, setShowTempDialog] = useState(false);
  const {canCreatePost, remainingPosts, canUsePremiumTemplates} = useRateLimit();
  const {color} = data?.template || {};
  const navigate = useNavigate();
  const [showPicker, setShowPicker] = useState(false);
  const paletteRef = useRef();
  const {generatePost, completionMessages, completionLoading, isStreaming} = useCompletionStream();
  const {showError} = useGlobalState();

  /** Load the Post to edit */
  useEffect(() => {
    if (jobId) {
      const userRef = doc(db, "users", user.uid);
      const docRef = doc(userRef, "posts", jobId);

      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          setData(doc.data());
        } else {
          errorhandler.record(new Error("Unable to load post"));
          showError(`We couldn\'t load that post`);
        }
      }, (error) => {
        errorhandler.record(error);
        showError(`We couldn\'t load that post`);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [db, jobId, user.uid]);

  /** Color Palette Events */
  useEffect(() => {
    const handleClickOutsidePalette = (event) => {
      if (paletteRef.current && !paletteRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutsidePalette);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePalette);
    };
  }, []);

  /** Call to Chat GPT to write post */
  useEffect(() => {
    if (data?.state === 3 && !data?.post) {
      generatePost(jobId);
    }
  }, [data?.state, data?.post]);

  const handleRegeneratePost = async (temp, length, useEmojis) => {
    recordEvent("regenerate_post");
    const userRef = doc(db, "users", user.uid);
    const docRef = doc(userRef, "posts", jobId);
    const newData = {
      ...data,
      post: null,
      state: 3,
      stateText: "Writing Post",
      gptConfig: {
        temperature: temp,
        length,
        useEmojis,
      },
    };


    // setData(newData);
    await updateDoc(docRef, newData);
    setShowTempDialog(false);
    // await generatePost(jobId);
  };

  /** Finalize the post */
  const handleSave = async () => {
    try {
      if (jobId && data) {
        setShowConfirm(false);
        const userRef = doc(db, "users", user.uid);
        const docRef = doc(userRef, "posts", jobId);
        await updateDoc(docRef, {...data, isLoading: true});

        const updatePostStatus = httpsCallable(functions, "finalizePost");

        const url = `${window.location.protocol}//${window.location.host}/post?uid=${user.uid}&postId=${jobId}`;
        await updatePostStatus({jobId, url});
        recordEvent("post_finalized");
        navigate(`/posts/view?jobid=${jobId}`);
      }
    } catch (error) {
      errorhandler.record(error);
      showError(`There was a problem publishing this post. We have been notified`);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleInputChange = (value, field) => {
    const newData = {...data};
    newData.vehicleData[field] = value;
    setData(newData);
  };

  const handleImageChange = (value, field) => {
    const newData = {...data};
    newData.vehicleData[field] = value;
    setData(newData);
  };

  const handleTemplateChange = (value, field) => {
    const newData = {...data};
    newData.template[field] = value;
    setData(newData);
  };

  const handleCloseDialog = () => {
    setShowConfirm(false);
  };

  const handleCloseTempDialog = () => {
    setShowTempDialog(false);
  };

  const handlePostChange = (event) => {
    const newData = {...data};
    newData.post = event.target.value;
    setData(newData);
  };

  if (!canCreatePost && !jobId) {
    return <PostLimit />;
  }

  if (!data) {
    return null;
  }

  if (!data?.initialized) {
    // Show loading sequence
    return <LoadingSequence state={data?.state} />;
  }

  return (
    <CommonContainer>

      <TitleHeader>
        <TitleText>Edit Post</TitleText>
        <TitleActions>
          <Button
            variant="contained"
            color="success" size="small"
            style={{marginRight: 15}}
            disabled={completionLoading || isStreaming}
            onClick={() => setShowConfirm(true)}
            startIcon={<FontAwesomeIcon icon={faUpload} />}
          >PUBLISH</Button>
          {remainingPosts !== null && <Chip label={`${remainingPosts} posts remaining`} color="primary" variant="contained" />}
        </TitleActions>
      </TitleHeader>

      <ConfirmDialog open={showConfirm} handleClose={handleCloseDialog} handleSave={handleSave} />
      <TemperatureDialog gptConfig={data?.gptConfig} open={showTempDialog} handleClose={handleCloseTempDialog} handleSave={handleRegeneratePost} />
      {data?.isLoading && data?.state !== -1 && <Loading text={data?.stateText} />}
      <Grid container xs={12} spacing={{
        xs: 0,
        sm: 0,
        md: 2,
      }} sx={{my: {xs: 0}, p: {xs: 0, md: 0}}}>
        <Grid item xs={12} md={6} >
          <Paper style={{padding: 15}}>
            <div style={{position: "relative"}}>

              <Box sx={{display: "flex", mb: 2}}>
                <ButtonGroup variant="contained">
                  <Button
                    onClick={() => handleTabChange(null, 0)}
                    variant={tabValue === 0 ? "contained" : "outlined"}
                  >
                                            Edit Text
                  </Button>
                  <Button
                    onClick={() => handleTabChange(null, 1)}
                    variant={tabValue === 1 ? "contained" : "outlined"}
                  >
                                            Edit Vehicle
                  </Button>
                </ButtonGroup>
              </Box>


              <TabPanel value={tabValue} index={0}>
                {!isStreaming && !completionLoading && <PostAction>
                  <EditIcons
                    onClick={() => setShowTempDialog(!showTempDialog)}
                    icon={faRotateRight}
                  />
                  <EditIcons
                    color={editable ? "forestgreen" : "black"}
                    onClick={() => setEditable(!editable)}
                    icon={editable ? faFloppyDisk : faEdit}
                  />
                </PostAction>}
                {completionLoading && (<GeneratingPost>
                  <span>Generating Text</span>
                  <CircularProgress size="3rem" color="primary" />
                </GeneratingPost>)}

                {!data?.post && isStreaming && <PostText>
                  {completionMessages.map((message, index) =>
                    message.content
                        .split("\n")
                        .map((text, i) =>
                          <React.Fragment key={i}>
                            {text}
                            <br/>
                          </React.Fragment>,

                        ))}
                </PostText>}

                {data?.post && editable && <TextField multiline fullWidth value={data?.post} onChange={handlePostChange} />}
                {data?.post && !editable && <PostText dangerouslySetInnerHTML={{__html: data?.post.replace(/\n/g, "<br />")}} />}

              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <PostAction>
                  {showPicker && <Palette ref={paletteRef}>
                    <SketchPicker
                      color={color}
                      onChangeComplete={(color) => handleTemplateChange(color.hex, "color")}
                    />
                  </Palette>}
                  <ColorPickerContainer color={color} onClick={() => setShowPicker(!showPicker)}>
                                    Change Color
                  </ColorPickerContainer>
                </PostAction>
                <form>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": {my: 1, mx: 1, width: "25ch"},
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      InputLabelProps={{shrink: true}}
                      label="Year"
                      value={data?.vehicleData?.year}
                      onChange={(e) => handleInputChange(e.target.value, "year")}
                      size="small" />

                    <TextField
                      InputLabelProps={{shrink: true}}
                      value={data?.vehicleData?.make}
                      onChange={(e) => handleInputChange(e.target.value, "make")}
                      label="Make"
                      size="small"
                    />

                    <TextField
                      InputLabelProps={{shrink: true}}
                      value={data?.vehicleData?.model} onChange={(e) => handleInputChange(e.target.value, "model")} label="Model" size="small" />

                    <TextField
                      InputLabelProps={{shrink: true}}
                      value={data?.vehicleData?.mileage} onChange={(e) => handleInputChange(e.target.value, "mileage")} label="Odometer" size="small" />

                    <TextField
                      InputLabelProps={{shrink: true}}
                      value={data?.vehicleData?.price} onChange={(e) => handleInputChange(e.target.value, "price")} label="Price" size="small" />

                    <TextField
                      InputLabelProps={{shrink: true}}
                      value={data?.vehicleData?.vin} onChange={(e) => handleInputChange(e.target.value, "vin")} label="VIN" size="small" />

                    <TextField
                      InputLabelProps={{shrink: true}}
                      value={data?.vehicleData?.stockNumber} onChange={(e) => handleInputChange(e.target.value, "stockNumber")} label="Stock Number" size="small" />

                    <TextField
                      InputLabelProps={{shrink: true}}
                      value={data?.vehicleData?.mainImage} onChange={(e) => handleInputChange(e.target.value, "mainImage")} label="Image" size="small" />

                  </Box>
                </form>
                <Images data={data} handleImageChange={handleImageChange} />
              </TabPanel>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ph: 0}}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <PostWrapper>
              <PostBody>
                <Preview data={data} handleTemplateChange={handleTemplateChange} canUsePremiumTemplates={canUsePremiumTemplates} />
                {/* <Button onClick={() => {*/}
                {/*    handleClearPost(null);*/}
                {/*    generatePost(jobId)*/}
                {/* }}>Regenerate</Button>*/}

              </PostBody>
            </PostWrapper>
          </Box>
        </Grid>
      </Grid>
    </CommonContainer>
  );
}

export default EditPost;
