/**
 * Class representing an error handler.
 *
 * @class
 */
class errorhandler {
  /**
   * Method to record error messages.
   *
   * @param {Error} e - The error object.
   */
  record(e) {
    console.log("error", e);
  }
}

// eslint-disable-next-line new-cap
export default new errorhandler();
