import * as React from "react";
import {AppBar, Box, Toolbar, Container, Button, Hidden} from "@mui/material";
import logoImg from "../../../assets/logo.png";
import {Logo, ButtonWrapper, Menu, MenuLink, MenuItem} from "./styles";
import {useNavigate} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../../firebase/clientApp";
const pages = ["How it works", "Styles", "Pricing"];

/**
 * ResponsiveAppBar component.
 *
 * @component
 * @return {JSX.Element} Landing component.
 */
function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  return (
    <AppBar style={{background: "white"}} position="sticky" color="primary">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Hidden mdUp>
            <Box sx={{flexGrow: 1, textAlign: "left"}}>
              <a href="/" title="Auto Influence Home">
                <Logo src={logoImg} style={{marginTop: 8}} width="170" />
              </a>
            </Box>
          </Hidden>

          <Hidden mdDown>
            <Box sx={{flexGrow: 1, textAlign: "left"}}>
              <a href="/" title="AutoInfluence Home">
                <Logo src={logoImg} style={{marginTop: 8}} />
              </a>
            </Box>
            <Box sx={{flexGrow: 1}} />
            <Menu style={{listStyleType: "none", alignItems: "flex-end", flex: 1}}>
              {pages.map((page, i) => (
                <MenuItem key={`menu_${i}`} style={{marginLeft: 5}}>
                  <MenuLink
                    key={page}
                    to={page.toLowerCase().replace(/ /g, "-")}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    sx={{my: 2, color: "white", display: "block"}}
                  >
                    {page}
                  </MenuLink>
                </MenuItem>
              ))}
            </Menu>
          </Hidden>

          <ButtonWrapper>
            <Button size="small" variant="outlined" onClick={() => navigate("/create")}>{user ? "LOGIN" : "FREE TRIAL"}</Button>
          </ButtonWrapper>
        </Toolbar>

      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
