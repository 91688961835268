import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CardContent, Typography, Grid, Chip, CardActions, CardActionArea, CardMedia} from "@mui/material";
import {collection, onSnapshot, orderBy, query} from "firebase/firestore";
import {getFirestore, doc, updateDoc} from "firebase/firestore";
import {useAuth} from "../../context/auth-context";
import {app} from "../../firebase/clientApp";
import {StyledCard} from "./styles";
import moment from "moment";
import placeholder from "../../assets/placeholder.jpg";
import {useNavigate} from "react-router-dom";
import {NoPostsWrapper, NoPostsIcon, NoPostsText} from "./styles";
import {faBeerMugEmpty} from "@fortawesome/pro-light-svg-icons";
import CommonContainer from "../../components/common/Layout/Container";
import errorhandler from "../../classes/errorhandler";
import {useGlobalState} from "../../context/state-context";
import {TitleActions, TitleHeader, TitleText} from "./Edit/styles";
import useRateLimit from "../../hooks/useRateLimit";

/**
 * UserPosts is a React component that displays a list of posts created by the current user.
 * It allows the user to navigate to the edit page for each post, or to archive a post.
 * It also displays a dialog to confirm the archiving action.
 *
 * @component
 * @example
 * // Render the UserPosts component
 * <UserPosts />
 *
 * @return {React.Element} The rendered UserPosts component
 *
 * @module components/UserPosts
 */
function UserPosts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [toDelete, setToDelete] = useState(null); // track the post to be deleted
  const {user} = useAuth();
  const db = getFirestore(app);
  const navigate = useNavigate();
  const {remainingPosts} = useRateLimit();
  const {showError} = useGlobalState();

  useEffect(() => {
    const userRef = doc(db, "users", user.uid);
    const postsCollection = collection(userRef, "posts");
    const q = query(postsCollection, orderBy("timestamp", "desc")); // Sort by timestamp in descending order

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const posts = querySnapshot.docs
          .map((doc) => ({id: doc.id, ...doc.data()}))
          .filter((post) => !post.archived);
      setPosts(posts);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [db, user.uid]);

  const imageHandler = (post) => {
    if (post.base64) {
      return `data:image/png;base64,${post.base64}`;
    }

    if (post?.vehicleData?.mainImage) {
      return post?.vehicleData?.mainImage;
    }

    return placeholder;
  };

  const handleDelete = async () => {
    try {
      if (toDelete) {
        const postRef = doc(db, "users", user.uid, "posts", toDelete);
        await updateDoc(postRef, {
          archived: true,
        });
        setToDelete(null);
        setOpenDialog(false);
      }
    } catch (error) {
      errorhandler.record(error);
      showError(`There was a problem archiving this post`);
    }
  };

  return (
    <CommonContainer>
      <TitleHeader>
        <TitleText>Your Posts</TitleText>
        <TitleActions>
          {remainingPosts !== null && <Chip label={`${remainingPosts} posts remaining`} color="primary" variant="contained" />}
        </TitleActions>
      </TitleHeader>
      <Grid container spacing={2}>
        {!posts.length && !loading && (
          <NoPostsWrapper>
            <NoPostsText>No posts yet</NoPostsText>
            <NoPostsIcon icon={faBeerMugEmpty} />
            <div>
              <Button onClick={() => navigate("/create")} variant="contained">Start Creating</Button>
            </div>
          </NoPostsWrapper>
        )}
        {posts.map((post, index) => (
          <Grid key={`posts_${index}`} item xs={12} md={4}>
            <StyledCard key={index} style={{cursor: "pointer"}} onClick={() => {
                                post.state !== 5 ?
                                    navigate(`/posts/edit/${post.id}`) : navigate(`/posts/view?jobid=${post.id}`);
            }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="194"
                  image={imageHandler(post)}
                  alt="Paella dish"
                />
                <CardContent>
                  <Typography variant="body2">
                    {post.vehicleData.year} {post.vehicleData.make} {post.vehicleData.model}
                  </Typography>
                  <Typography variant="body2">
                    {moment(post.timestamp.toDate()).format("MM/DD/YYYY")}
                  </Typography>
                </CardContent>
                <CardActions style={{justifyContent: "space-between"}}>
                  <Chip label={post.stateText} color={post.state === 5 ? "success" : "primary"} />
                  <Button size="small" color="error" variant="contained" onClick={(event) => {
                    event.stopPropagation(); // prevent navigation
                    setToDelete(post.id);
                    setOpenDialog(true);
                  }}>Archive</Button>
                </CardActions>
              </CardActionArea>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{"Delete Post"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
                        Are you sure you want to delete this post? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
                        Delete
          </Button>
        </DialogActions>
      </Dialog>
    </CommonContainer>
  );
}

export default UserPosts;

