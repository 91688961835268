import React from "react";
import {
  Button,
} from "@mui/material";

import {useNavigate} from "react-router-dom";
import CommonContainer from "../../../common/Layout/Container";
import {Icon, Wrapper} from "./styles";
import {faCheckCircle} from "@fortawesome/pro-light-svg-icons";

/**
 * BillingSuccess component.
 *
 * @component
 * @return {JSX.Element} Landing component.
 */
function BillingSuccess() {
  const navigate = useNavigate();

  return (
    <CommonContainer>
      <Wrapper>
        <Icon icon={faCheckCircle} />
        <h3>Success</h3>
        <p>Your account has been successfully upgraded</p>
        <Button variant="contained" onClick={() => navigate("/create")}>Create a Post</Button>
      </Wrapper>
    </CommonContainer>
  );
}

export default BillingSuccess;
