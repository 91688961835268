import {useState, useEffect} from "react";
import {collection, getFirestore, orderBy, onSnapshot, query} from "firebase/firestore";
import {useAuth} from "../context/auth-context";
import {app} from "../firebase/clientApp";

/**
 * useDealerSelection is a custom hook that fetches the list of dealers for the current user and manages the state of the selected dealer.
 *
 * @function
 * @example
 * // Use the useDealerSelection hook
 * const {dealers, selectedDealer, handleChange} = useDealerSelection();
 *
 * @return {Object} An object containing the following properties:
 * - dealers: An array of dealer objects.
 * - selectedDealer: The ID of the currently selected dealer.
 * - handleChange: A function to change the selected dealer.
 *
 * @module hooks/useDealerSelection
 */
export function useDealerSelection() {
  const {user} = useAuth();
  const db = getFirestore(app);

  const [dealers, setDealers] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState("");

  useEffect(() => {
    const dealersRef = collection(db, `users/${user.uid}/dealers`);
    const q = query(dealersRef, orderBy("name"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newDealers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setDealers(newDealers);
      if (newDealers.length === 1) {
        setSelectedDealer(newDealers[0].id);
      }
    });

    // Clean up the subscription on unmount
    return unsubscribe;
  }, []);

  const handleChange = (event) => {
    setSelectedDealer(event.target.value);
  };

  return {dealers, selectedDealer, handleChange};
}
