import React from "react";
import {Container, Box, Button, Typography} from "@mui/material";
import {styled} from "@mui/system";
import {useNavigate} from "react-router-dom";

const BackgroundBox = styled(Box)({
  backgroundColor: "#eff5fa",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const WhiteBox = styled(Box)({
  backgroundColor: "#ffffff",
  padding: "20px",
  borderRadius: "10px",
  marginTop: "25px",
  width: "400px",
  alignItems: "center",
  justifyContent: "center",
});

/**
 * VerifyEmail is a component that displays a verification message and a button to navigate back to the login page.
 * @return {JSX.Element} A verification message with a button to navigate back to the login page.
 */
function VerifyEmail() {
  const navigate = useNavigate();
  return (
    <BackgroundBox component="main" maxWidth="xs">
      <WhiteBox>
        <Container>
          <Typography variant="h5" align="center" color="text.secondary" component="h5">
                        Verify your email address
          </Typography>
          <Typography variant="body1" align="center" color="text.secondary" component="p" sx={{mt: 2}}>
                        We&apos;ve sent an email to your address. Please click the link in the email to verify your account.
          </Typography>
          <Button
            onClick={() => navigate("/auth/email")}
            align="center" style={{alignSelf: "center", marginTop: 25}} size="small" variant="contained">
                        Back to login
          </Button>
        </Container>
      </WhiteBox>
    </BackgroundBox>
  );
}

export default VerifyEmail;
