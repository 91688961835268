import {Box} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

/**
 * TabPanel is a React component that serves as a container for tab content.
 * It only renders its children if the current tab value matches its index.
 *
 * @component
 * @param {Object} props The props for the component
 * @param {React.ReactNode} props.children The content of the tab panel
 * @param {number} props.value The current tab value
 * @param {number} props.index The index of the tab panel
 * @param {Object} props.other Any other props to pass to the div element
 *
 * @example
 * // Render the TabPanel component
 * <TabPanel value={tabValue} index={0}>
 *   Tab content
 * </TabPanel>
 *
 * @return {React.Element} The rendered TabPanel component
 *
 * @module components/TabPanel
 */
export default function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{pt: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  other: PropTypes.object,
};
