import React from "react";
import {faWarning} from "@fortawesome/pro-light-svg-icons";
import {Wrapper, Icon} from "./styles";
import CommonContainer from "../common/Layout/Container";
import {
  Button,
} from "@mui/material";
import {useNavigate} from "react-router-dom";

/**
 * PostLimit component.
 *
 * @component
 * @return {JSX.Element} PostLimit component.
 */
export default function PostLimit() {
  const navigate = useNavigate();
  return (
    <CommonContainer>
      <Wrapper>
        <Icon icon={faWarning} />
        <p>You&apos;ve reached your plans post limit</p>
        <p>Upgrade now to keep the momentum going!</p>
        <Button variant="contained" onClick={() => navigate("/dashboard/billing")}>upgrade your subscription</Button>
      </Wrapper>
    </CommonContainer>
  );
}
