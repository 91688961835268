import styled from "styled-components";
import {Link} from "react-router-dom";
export const Title = styled.h1`
  color: red;
  font-size: 38px;
  font-family: Plus Jakarta Sans,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,"Noto Sans","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
`;

export const Logo = styled.img`

`;

export const MainNav = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const NavItem = styled.li`
  margin: 0 15px;
  display: flex;
`;

export const NavLink = styled(Link)`
  color: black;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    color: gray;
  }
`;

export const ButtonWrapper = styled.div`
  float: right;
`;
