import React from "react";
import {
  StyledBody,
  Price,
  InfoBar,
  Year,
  YMM,
  StyledContent,
  Image,
  Mileage,
} from "./styles";
import PropTypes from "prop-types";

/**
 * Template is a React component that displays car information.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.year - The year of the car.
 * @param {string} props.make - The make of the car.
 * @param {string} props.model - The model of the car.
 * @param {string} props.price - The price of the car.
 * @param {string} props.mainImage - The URL of the main image of the car.
 * @param {string} props.odometer - The odometer reading of the car.
 * @param {string} props.primaryColor - The primary color for the info bar.
 *
 * @example
 * // Use the Template component
 * <Template year="2020" make="Toyota" model="Camry" price="$20,000" mainImage="https://example.com/car.jpg" odometer="30,000 miles" primaryColor="#000" />
 *
 * @return {ReactElement} Returns a layout structure displaying car information.
 *
 * @module components/Template
 */
const Template = ({
  year,
  make,
  model,
  price,
  mainImage,
  odometer,
  primaryColor,
}) => (
  <StyledBody>
    <StyledContent>

      <Price>{price}</Price>
      {/* <Grid container spacing={2} justifySelf="center">*/}
      {/* {year && <Grid item xs={12} justifyContent="flex-start">*/}
      {/*    <DataIcon size="lg" icon={faCalendar} color="#000" /><DataText>{year}</DataText>*/}
      {/* </Grid>}*/}
      {/* {odometer && <Grid item xs={12}>*/}
      {/*    <DataIcon size="lg" icon={faGaugeHigh} color="#000" /><DataText>{odometer}</DataText>*/}
      {/* </Grid>}*/}
      {/* {stockNumber &&*/}
      {/* <Grid item xs={12}>*/}
      {/*    <DataIcon size="lg" icon={faCar} color="#000" /><DataText>Stock #: {stockNumber}</DataText>*/}
      {/* </Grid>}*/}
      {/* {vin && <Grid item xs={12}>*/}
      {/*    <DataIcon size="lg" icon={faCogs} color="#000" /><DataText>VIN: {vin}</DataText>*/}
      {/* </Grid>}*/}
      {/* </Grid>*/}
    </StyledContent>

    <InfoBar primaryColor={primaryColor}>
      <Year>{`${year}`}</Year>
      <div>
        <YMM>{`${make}`}</YMM>
        <YMM>{`${model}`}</YMM>
      </div>
      <Mileage>{odometer}</Mileage>

      {/* {financing && <FooterText>Financing Available</FooterText>}*/}
    </InfoBar>
    <Image crossorigin src={mainImage} alt={`${year} ${make} ${model}`} width="512"/>
  </StyledBody>
);

Template.propTypes = {
  /**
   * The year of the car.
   */
  year: PropTypes.string.isRequired,

  /**
   * The make of the car.
   */
  make: PropTypes.string.isRequired,

  /**
   * The model of the car.
   */
  model: PropTypes.string.isRequired,

  /**
   * The price of the car.
   */
  price: PropTypes.string.isRequired,

  /**
   * The URL of the main image of the car.
   */
  mainImage: PropTypes.string.isRequired,

  /**
   * The odometer reading of the car.
   */
  odometer: PropTypes.string.isRequired,

  /**
   * The primary color for the info bar.
   */
  primaryColor: PropTypes.string.isRequired,
};

export default Template;

