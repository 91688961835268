// CarInfo.js
import React from "react";
import {
  StyledBody,
  ImageContainer,
  PriceBanner,
  Price,
  BlackBar,
  YMM,
  StyledContent,
  DataIcon,
  Image,
} from "./styles";

import PropTypes from "prop-types";
import {faGaugeHigh, faCalendar, faCogs, faCar} from "@fortawesome/pro-solid-svg-icons";


import {Grid} from "@mui/material";

/**
 * Template is a React component that displays car information.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.year - The year of the car.
 * @param {string} props.make - The make of the car.
 * @param {string} props.model - The model of the car.
 * @param {string} props.price - The price of the car.
 * @param {string} props.mainImage - The URL of the main image of the car.
 * @param {string} props.odometer - The odometer reading of the car.
 * @param {string} props.vin - The VIN of the car.
 * @param {string} props.stockNumber - The stock number of the car.
 * @param {string} props.primaryColor - The primary color for the info bar.
 *
 * @example
 * // Use the Template component
 * <Template year="2020" make="Toyota" model="Camry" price="$20,000" mainImage="https://example.com/car.jpg" odometer="30,000 miles" vin="1HGCM82633A123456" stockNumber="123456" primaryColor="#000" />
 *
 * @return {ReactElement} Returns a layout structure displaying car information.
 *
 * @module components/Template
 */
const Template = ({
  year,
  make,
  model,
  price,
  mainImage,
  odometer,
  vin,
  stockNumber,
  primaryColor,
}) => (
  <StyledBody>
    <ImageContainer>
      <PriceBanner primaryColor={primaryColor}>
        <Price>{price}</Price>
      </PriceBanner>

      <Image crossorigin src={mainImage} alt={`${year} ${make} ${model}`} width="512"/>
      <BlackBar primaryColor={primaryColor}>
        <YMM>{`${year} ${make} ${model}`}</YMM>

      </BlackBar>
    </ImageContainer>
    <StyledContent>
      <Grid container spacing={2} justifySelf="center">
        {year && <Grid item xs={6}>
          <DataIcon size="lg" icon={faCalendar} color="#000" />{year}
        </Grid>}
        {odometer && <Grid item xs={6}>
          <DataIcon size="lg" icon={faGaugeHigh} color="#000" />{odometer}
        </Grid>}
        {vin && <Grid item xs={6}>
          <DataIcon size="lg" icon={faCogs} color="#000" />{vin}
        </Grid>}
        {stockNumber &&
                <Grid item xs={6}>
                  <DataIcon size="lg" icon={faCar} color="#000" />{stockNumber}
                </Grid>}
      </Grid>
    </StyledContent>
  </StyledBody>
);

Template.propTypes = {
  /**
   * The year of the car.
   */
  year: PropTypes.string.isRequired,

  /**
   * The make of the car.
   */
  make: PropTypes.string.isRequired,

  /**
   * The model of the car.
   */
  model: PropTypes.string.isRequired,

  /**
   * The price of the car.
   */
  price: PropTypes.string.isRequired,

  /**
   * The URL of the main image of the car.
   */
  mainImage: PropTypes.string.isRequired,

  /**
   * The odometer reading of the car.
   */
  odometer: PropTypes.string.isRequired,

  /**
   * The VIN of the car.
   */
  vin: PropTypes.string.isRequired,

  /**
   * The stock number of the car.
   */
  stockNumber: PropTypes.string.isRequired,

  /**
   * The primary color for the info bar.
   */
  primaryColor: PropTypes.string.isRequired,
};

export default Template;

