import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const List = styled.ul`
  list-style-type: none;
  padding: 25px 0 25px 25px;
  margin: 0;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListItem = styled.li`
  margin: 10px 0;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Text = styled.span`
  font-family: Plus Jakarta Sans, sans-serif;
  margin-left: 10px;
  font-size: 1.2rem;
`;

export const CheckMark = styled(FontAwesomeIcon)`
  color: forestgreen;
  font-size: 1.2em;
`;

export const Waiting = styled(FontAwesomeIcon)`
  color: orange;
  font-size: 1.2em;
`;

export const IconWrapper = styled.div`
  width: 40px;
  text-align: center;
`;

export const TimerWrapper = styled.div`
  text-align: center;
  padding: 25px 25px;
  display: flex;
  flex-direction: column;
`;

export const TimerTitle = styled.h4`
  margin: 0;
  font-weight: normal;
  font-family: Plus Jakarta Sans, sans-serif;
`;

export const Timer = styled.span`
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 5rem;
  text-align: center;
  font-weight: normal;
  color: #afafaf;
`;
