import React, {useContext, useState} from "react";
import {
  Avatar,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Container,
  Button,
  Menu,
  Typography,
  MenuItem,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logoImg from "../../../assets/logo.png";
import logoMark from "../../../assets/logo_mark.png";
import {Logo, MainNav, NavItem, NavLink} from "./styles";
import {useAuth} from "../../../context/auth-context";
import {getAuth, signOut} from "firebase/auth";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase/clientApp";
import {useNavigate, Link} from "react-router-dom";
import {SubscriptionContext} from "../../../context/subscription-context";
import Loading from "../../common/Loading";

/**
 * ResponsiveAppBar component.
 *
 * @component
 * @return {JSX.Element} Landing component.
 */
function ResponsiveAppBar() {
  const {user} = useAuth();
  const auth = getAuth();
  const navigate = useNavigate();
  const [loadingPortal, setLoadingPortal] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openNav, setOpenNav] = useState(false);
  const {subscriptionStatus, loadingSubscriptionStatus, subscription} = useContext(SubscriptionContext);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleToggleNavMenu = () => {
    setOpenNav(!openNav);
  };

  const portalLink = async () => {
    try {
      setLoadingPortal(true);
      const func = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
      const {data} = await func({returnUrl: `${window.location.origin}/create/`});
      setLoadingPortal(false);
      window.location.assign(data.url);
    } catch (error) {
      console.log(error);
      // setLoadingPortal(false);
    }
  };

  return (
    <>
      <AppBar style={{background: "white"}} position="sticky" color="primary">
        {loadingPortal && <Loading text="Loading Billing Portal" />}
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Hidden mdUp>
              <IconButton
                size="large"
                aria-label="menu"
                color="primary"
                edge="start"
                onClick={handleToggleNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{flexGrow: 1, textAlign: "center"}}>
                <Link to="/" title="AutoInfluence Home">
                  <Logo src={logoMark} width="50" style={{marginTop: 5}} />
                </Link>
              </Box>

              <Box sx={{flexGrow: 0}}>
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                  <Avatar alt={user?.displayName} src={user?.photoURL ? user?.photoURL : "/static/images/avatar/2.jpg"} />
                </IconButton>
                {/* Similar to your original Menu component for User... */}
              </Box>
            </Hidden>

            <Hidden mdDown>
              <Box sx={{flexGrow: 1, textAlign: "left"}}>
                <Link to="/" title="AutoInfluence Home">
                  <Logo src={logoImg} />
                </Link>
              </Box>
              <Box sx={{flexGrow: 1}} />

              <MainNav>
                <NavItem>
                  <NavItem>
                    <NavLink
                      to="/create"
                      sx={{my: 2, color: "white", display: "block"}}
                    >
                                                Create Content
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to="/posts"
                      sx={{my: 2, color: "white", display: "block"}}
                    >
                                                Your Posts
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to="/dealerships"
                      sx={{my: 2, color: "white", display: "block"}}
                    >
                                                Your Dealerships
                    </NavLink>
                  </NavItem>
                </NavItem>
              </MainNav>
              <Box sx={{flexGrow: 0}}>
                {!loadingSubscriptionStatus &&
                                    <Button onClick={() => {
                                      if (subscriptionStatus) {
                                        portalLink();
                                        return;
                                      }
                                      navigate("/dashboard/billing");
                                    }} style={{marginRight: 15}} size="small" variant="contained">
                                      {subscriptionStatus ? `${subscription.name} plan` : "Trial"}
                                    </Button>}

                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                  <Avatar alt={user?.displayName} src={user?.photoURL ? user?.photoURL : "/static/images/avatar/2.jpg"} />
                </IconButton>
                <Menu
                  sx={{mt: "45px"}}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => {
                    handleCloseUserMenu();
                    portalLink();
                  }}>
                    <Typography textAlign="center">Billing</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleCloseUserMenu();
                    signOut(auth);
                  }}>
                    <Typography textAlign="center">Sign Out</Typography>
                  </MenuItem>
                </Menu>
                {/* Similar to your original Menu component for User... */}
              </Box>
            </Hidden>


          </Toolbar>

          <Hidden mdUp>
            <Drawer anchor={"left"} open={openNav} onClose={handleToggleNavMenu}>
              <Box
                sx={{width: 200}}
                role="presentation"
              >
                <List width="50%">

                  <ListItem button onClick={() => navigate("/create")}>
                    <ListItemText primary="Create" onClick={handleToggleNavMenu}/>
                  </ListItem>

                  <ListItem button onClick={() => navigate("/posts")}>
                    <ListItemText primary="Your Posts" onClick={handleToggleNavMenu}/>
                  </ListItem>

                  <ListItem button onClick={() => navigate("/dealerships")}>
                    <ListItemText primary="Your Dealerships" onClick={handleToggleNavMenu}/>
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </Hidden>

        </Container>
      </AppBar>
    </>
  );
}
export default ResponsiveAppBar;
