import {Container, Stepper, Grid} from "@mui/material";
import styled from "styled-components";

export const StyledContainer = styled(Container)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: white;
    z-index: 100;
    flex-direction: column;
  }
`;

export const StyledStepper = styled(Stepper)`
  && {
    background: transparent;
    margin-bottom: 2rem;
    width: 80%;
  }
`;

export const StyledGrid = styled(Grid)`
  && {
    width: 80%;
    max-width: 800px;
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;
