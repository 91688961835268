import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const PromoBanner = styled.div`
  background-color: #f5f5f4;
`;

export const InnerWrapper = styled.div`
  flex-direction: row;
  padding: 5px 0;
  display: flex;
`;

export const PromoWrapper = styled.div`
  margin-left: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PromoText = styled.span`
  color: #9a9591;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 13px;
`;
export const PromoIcon = styled(FontAwesomeIcon)`
  color: #22c55e;
  margin-right: 5px;
`;
