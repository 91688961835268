import React from "react";
import {Accordion, AccordionSummary, AccordionDetails, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonContainer from "../../Layout/Container";

/**
 * FAQ is a React component that displays the FAQ of the website.
 *
 * @function
 *
 * @example
 * // Use the Terms component
 * <FAQ />
 *
 * @return {ReactElement} Returns a layout structure displaying the terms of use of the website.
 *
 * @module components/FAQ
 */
export default function FAQ() {
  return (
    <CommonContainer landing>
      <h1>FAQ</h1>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How does Auto Influence work?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* eslint-disable-next-line max-len */}
                        Auto Influence uses AI to help vehicle dealerships create engaging social media content. Simply sign up, provide some basic information, and start creating compelling social media assets.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Do I need any special software to use Auto Influence?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
                        All you need is a web browser and an internet connection. Auto Influence runs entirely online, so there&apos;s no software to download or install.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How is my data handled?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* eslint-disable-next-line max-len */}
                        At Auto Influence, we take your privacy seriously. We use your data to provide and improve our services, and we never sell your data to third parties. For more information, please see our Privacy Policy.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How can I get in touch with you?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
                        You can reach out to us anytime at hello@autoinfluence.ai. We look forward to hearing from you!
          </Typography>
        </AccordionDetails>
      </Accordion>
    </CommonContainer>
  );
}
