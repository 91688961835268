import styled from "styled-components";
import {Paper} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Image = styled.img`
  width: 100%;
`;

export const StyledPaper = styled(Paper)`
  background-color: #f9f9f9;
`;

export const CustomCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const ActionHeader = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 5px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  &:hover {
    background-color: #eaeaea;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 22px;
  color: #afafaf;
`;

export const ActionText = styled.span`
  font-family: Plus Jakarta Sans, sans-serif;
  margin-left: 10px;
  color: #afafaf;
  font-size: 14px;
`;
