import styled from "styled-components";

export const ImageGrid = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 10px;
  align-items: stretch;
  margin: 15px 0;
`;

export const StyledImage = styled.img`
  width: 100%;
  cursor: pointer;
  transition: transform 0.2s;
  object-fit: cover;
  height: 100px;
  ${(props) => props.selected && `border: 2px ${props.color} solid;`}
  

  &:hover {
    transform: scale(1.1);
  }
`;
