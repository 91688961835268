import React, {useContext, useState, useEffect} from "react";
import {auth} from "../firebase/clientApp";
import {doc, onSnapshot} from "firebase/firestore";
import errorhandler from "../classes/errorhandler";
import firestoreDB from "../classes/firestore";
import PropTypes from "prop-types";

/**
 * Context for authentication-related operations and data.
 * @type {React.Context}
 */
const AuthContext = React.createContext();
const {Provider} = AuthContext;

/**
 * Hook for accessing the authentication context.
 * @return {Object} The authentication context object.
 */
export function useAuth() {
  return useContext(AuthContext);
}

/**
 * Provider component for the authentication context.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @return {React.ReactNode} The rendered component.
 */
const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState();
  const [loading, setLoading] = useState(true);

  const {getUser} = firestoreDB;

  useEffect(() => {
    const unsubscribeUser = auth.onAuthStateChanged((userAccount) => {
      if (userAccount) {
        setUser(userAccount);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    if (!user) {
      return;
    }

    const collection = getUser();
    const d = doc(collection, user.uid);
    const unsubscribeProfile = onSnapshot(d, (snapshot) => {
      const profile = snapshot.data();
      setUserProfile(profile);
      if (profile) {
        setLoading(false);
      }
    }, (error) => errorhandler.record(error));

    return () => {
      unsubscribeUser();
      unsubscribeProfile();
    };
  }, [getUser, user]);

  const value = {
    user,
    userProfile,
    loading,
  };

  return (
    <Provider
      value={value}
    >
      {children}
    </Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {AuthContext, AuthProvider};
