import React, {useState} from "react";
import {Container, Typography, Grid, Divider, Box, Dialog} from "@mui/material";
import sample1 from "../../../assets/sample_posts/sample_post1.png";
import sample2 from "../../../assets/sample_posts/sample_post2.jpg";
import sample3 from "../../../assets/sample_posts/sample_post3.png";

/**
 * SamplePosts component.
 *
 * @component
 * @return {JSX.Element} Landing component.
 */
function SamplePosts() {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{width: "100%", padding: 3}}>
        <Typography component="h1" color="#1b145d" variant="h3" align="center" gutterBottom>
                    Never Write a Post Again
        </Typography>
        <Typography variant="body1" color="inherit" paragraph align="center">
                    All these posts were written entirely by Auto Influence AI.
        </Typography>
        <Typography variant="body1" color="inherit" paragraph align="center">
                    Simply point it to a vehicle listing, or enter some basic vehicle details and the AI does the rest.
        </Typography>
        <Divider variant="middle" sx={{margin: "20px 0"}} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={4}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}} onClick={() => handleOpen(sample1)}>
              <img src={sample1} alt="Sample Post 1" style={{maxWidth: "100%"}} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}} onClick={() => handleOpen(sample2)}>
              <img src={sample2} alt="Sample Post 2" style={{maxWidth: "100%"}} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}} onClick={() => handleOpen(sample3)}>
              <img src={sample3} alt="Sample Post 3" style={{maxWidth: "100%"}} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <img src={selectedImage} alt="Selected" style={{width: "100%", height: "auto"}} />
      </Dialog>
    </Container>
  );
}

export default SamplePosts;
