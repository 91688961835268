import React, {useEffect, useState} from "react";
import {useAuth} from "../../../context/auth-context";
import {addDoc, collection, doc, getFirestore, onSnapshot, orderBy, query, serverTimestamp} from "firebase/firestore";
import {app, functions} from "../../../firebase/clientApp";
import {useGlobalState} from "../../../context/state-context";
import {httpsCallable} from "firebase/functions";
import {recordEvent} from "../../../classes/analytics";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import errorhandler from "../../../classes/errorhandler";
import CommonContainer from "components/common/Layout/Container";
import PostLimit from "components/PostLimit";
import PropTypes from "prop-types";
import {
  Box,
  Button, ButtonGroup, Checkbox, Chip,
  CircularProgress,
  Hidden,
  Container, FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import screenshot from "../../../assets/copy_url.jpg";
import {useNavigate} from "react-router-dom";
import useRateLimit from "../../../hooks/useRateLimit";
import {TitleActions, TitleHeader, TitleText} from "../../Posts/Edit/styles";

const fileTypes = ["JPG", "PNG", "GIF"];

const marks = [
  {
    value: 0,
    label: "Formal",
  },
  {
    value: 50,
    label: "Friendly",
  },
  {
    value: 100,
    label: "Creative",
  },
];

const LengthMarks = [
  {
    value: 0,
    label: "Short",
  },
  {
    value: 50,
    label: "Average",
  },
  {
    value: 100,
    label: "Long",
  },
];

/**
 * TabPanel is a React component that serves as a container for tab content.
 * It only renders its children if the current tab value matches its index.
 *
 * @component
 * @param {Object} props The props for the component
 * @param {React.ReactNode} props.children The content of the tab panel
 * @param {number} props.value The current tab value
 * @param {number} props.index The index of the tab panel
 * @param {Object} props.other Any other props to pass to the div element
 *
 * @example
 * // Render the TabPanel component
 * <TabPanel value={tabValue} index={0}>
 *   Tab content
 * </TabPanel>
 *
 * @return {React.Element} The rendered TabPanel component
 *
 * @module components/TabPanel
 */
function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{pt: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  other: PropTypes.object,
};

/**
 * create is a React component that allows the user to create a new post.
 * It provides fields for entering the URL or vehicle data of the post, and a button for submitting the post.
 * It also provides a color picker for choosing the color of the post template, and a slider for adjusting the temperature of the post.
 *
 * @component
 * @example
 * // Render the create component
 * <create />
 *
 * @return {React.Element} The rendered create component
 *
 * @module components/create
 */
export default function VehiclePost() {
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const [temperature, setTemperature] = useState(50);
  const [length, setLength] = useState(50);
  const [useEmojis, setUseEmojis] = useState(true);
  const [financing, setFinancing] = useState(true);
  const [decodingVin, setDecodingVin] = useState(false);
  const {user} = useAuth();
  const db = getFirestore(app);
  const {showError} = useGlobalState();
  // eslint-disable-next-line no-unused-vars
  const [dealers, setDealers] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const {canCreatePost, remainingPosts, loadingRateLimit} = useRateLimit();
  const [vehicleData, setVehicleData] = useState({
    year: null,
    make: null,
    model: null,
    vin: null,
    price: null,
    mileage: null,
    description: null,
  });

  const [imageURL, setImageURL] = useState(null); // To store the URL of uploaded image
  const [uploadProgress, setUploadProgress] = useState(0); // To store the upload progress

  const decodeVin = async () => {
    try {
      setDecodingVin(true);
      const func = httpsCallable(functions, "vinDecode");
      const {data} = await func({vin: vehicleData?.vin});

      const {Year, Make, Model} = data?.attributes;

      const newValues = {
        year: Year,
        make: Make,
        model: Model,
      };

      setVehicleData({...vehicleData, ...newValues});
      setDecodingVin(false);
    } catch (error) {
      console.log(error);
      setDecodingVin(false);
    }
  };

  const handleImageChange = (file) => {
    if (file) {
      // const file = e.target.files[0];
      recordEvent("image_upload");
      const storage = getStorage();
      const storageRef = ref(storage, `users/${user.uid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed",
          (snapshot) => {
            console.log("progress", snapshot);
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            // Handle errors...
            console.log(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setImageURL(downloadURL); // Set the URL of the uploaded image
          },
      );
    }
  };

  useEffect(() => {
    const dealersRef = collection(db, `users/${user.uid}/dealers`);
    const q = query(dealersRef, orderBy("name"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newDealers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setDealers(newDealers);
      if (newDealers.length === 1) {
        setSelectedDealer(newDealers[0].id);
      }
    });

    // Clean up the subscription on unmount
    return unsubscribe;
  }, []);

  // const handleChange = (event) => {
  //   setSelectedDealer(event.target.value);
  // };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleVehicleDataChange = (e) => {
    setVehicleData({...vehicleData, [e.target.id]: e.target.value});
  };

  const handleSubmit = async () => {
    try {
      const userRef = doc(db, "users", user.uid);
      const episodesCollection = collection(userRef, "posts");

      const {year, make, model, description} = vehicleData;

      const docRef = await addDoc(episodesCollection, {
        url: year && make && model && description ? null : url,
        timestamp: serverTimestamp(),
        state: 0,
        stateText: "Starting",
        gptConfig: {
          temperature,
          useEmojis,
          length,
        },
        isLoading: true,
        vehicleData: {
          financing,
          mainImage: imageURL,
          ...vehicleData,
        },
        initialized: false,
        dealerId: selectedDealer,
        template: {
          color: "#E90226",
          templateId: 1,
        },
      });
      recordEvent("post_created");
      navigate(`/posts/edit/${docRef.id}`);
    } catch (error) {
      errorhandler.record(error);
      recordEvent("post_created_error");
      showError("There was a problem creating that post. Please contact us.");
    }
  };


  if (loadingRateLimit) {
    return (
      <CommonContainer>
        <Box style={{textAlign: "center"}}>
          <CircularProgress size="3rem" color="primary" />
        </Box>
      </CommonContainer>
    );
  }

  if (!canCreatePost && !loadingRateLimit) {
    return <PostLimit />;
  }

  return (
    <CommonContainer>
      <TitleHeader>
        <TitleText>Create Post</TitleText>
        <TitleActions>
          {remainingPosts !== null && <Chip label={`${remainingPosts} posts remaining`} color="primary" variant="contained" />}
        </TitleActions>
      </TitleHeader>
      <Container maxWidth="lg">
        <Grid container xs={12} spacing={{
          xs: 0,
          sm: 0,
          md: 2,
        }}>
          <Grid item xs={12} md={8} sx={{p: {xs: 0}, m: {xs: 0}}}>
            <Paper sx={{p: {xs: 1, md: 3}}}>

              <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mb: 2}}>
                <ButtonGroup variant="contained">
                  <Button
                    onClick={() => handleTabChange(null, 0)}
                    variant={tabValue === 0 ? "contained" : "outlined"}
                  >
                                    Paste Website
                  </Button>
                  <Button
                    onClick={() => handleTabChange(null, 1)}
                    variant={tabValue === 1 ? "contained" : "outlined"}
                  >
                                    Year/Make/Model
                  </Button>
                </ButtonGroup>
              </Box>

              <TabPanel value={tabValue} index={0}>
                <Typography variant="h6" gutterBottom style={{marginBottom: 20}}>
                                Go to your vehicle detail page and copy the url from the address bar, and paste it below:
                </Typography>

                <TextField
                  required
                  fullWidth
                  id="outlined-required"
                  label="Vehicle URL"
                  size="small"
                  value={url}
                  onChange={handleUrlChange}
                />
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{shrink: true}}
                      id="vin"
                      label="VIN"
                      size="small"
                      fullWidth
                      onChange={handleVehicleDataChange}
                      value={vehicleData?.vin}
                    />
                    <Button
                      onClick={() => decodeVin()}
                      disabled={!vehicleData?.vin || decodingVin}
                      variant="contained"
                      size="small">
                      {decodingVin ? <CircularProgress size="1.4rem" color="success" /> : "Decode"}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{shrink: true}}
                      id="year"
                      label="Year (required*)"
                      size="small"
                      fullWidth
                      value={vehicleData?.year}
                      onChange={handleVehicleDataChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{shrink: true}}
                      id="make"
                      label="Make (required*)"
                      size="small"
                      fullWidth
                      onChange={handleVehicleDataChange}
                      value={vehicleData?.make}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{shrink: true}}
                      id="model"
                      label="Model (required*)"
                      size="small"
                      fullWidth
                      onChange={handleVehicleDataChange}
                      value={vehicleData?.model}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField InputLabelProps={{shrink: true}} id="price" label="Price" size="small" fullWidth onChange={handleVehicleDataChange}/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField InputLabelProps={{shrink: true}} id="mileage" label="Mileage" size="small" fullWidth onChange={handleVehicleDataChange}/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField InputLabelProps={{shrink: true}} id="description" label="Description" size="small" fullWidth multiline rows={4} onChange={handleVehicleDataChange}/>
                  </Grid>
                  <Grid item xs={12}>
                    <p>Image Upload (required*)</p>
                    <FileUploader handleChange={handleImageChange} name="file" types={fileTypes} />
                  </Grid>
                  {uploadProgress > 0 && <LinearProgress variant="determinate" value={uploadProgress} />}
                  {imageURL && <img style={{marginTop: 15}} src={imageURL} width="200"/>}
                </Grid>
              </TabPanel>

              {/* Tone slider, checkboxes and submit button moved here */}

              <Box sx={{p: 3}}>
                <Typography variant="subtitle1" gutterBottom sx={{mt: 3}}>
                                Set the tone that the AI will use for your post
                </Typography>
                <Slider
                  aria-label="Custom marks"
                  defaultValue={50}
                  step={50}
                  valueLabelDisplay="auto"
                  marks={marks}
                  onChange={(e, value) => setTemperature(value)}
                />
              </Box>
              <Box sx={{px: 3}}>
                <Typography variant="subtitle1" gutterBottom sx={{mt: 3}}>
                                Set the length
                </Typography>
                <Slider
                  aria-label="Custom marks"
                  defaultValue={length}
                  step={50}
                  valueLabelDisplay="auto"
                  marks={LengthMarks}
                  onChange={(e, value) => setLength(value)}
                />
              </Box>
              <Box sx={{p: 3}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setUseEmojis(e.target.checked)}
                      defaultChecked
                      sx={{"& .MuiSvgIcon-root": {fontSize: 28}}}
                    />
                  }
                  label="Use Emojis"
                  sx={{mt: 3}}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setFinancing(e.target.checked)}
                      defaultChecked
                      sx={{"& .MuiSvgIcon-root": {fontSize: 28}}}
                    />
                  }
                  label="Financing Available?"
                  sx={{mt: 3}}
                />
              </Box>
              <Button fullWidth disabled={
                !(url || (vehicleData.year && vehicleData.make && vehicleData.model && imageURL))
              } variant="contained" color="primary" onClick={handleSubmit} sx={{mt: 3}}>
                            GENERATE POST
              </Button>
            </Paper>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom>
                        Instructions
              </Typography>
              <Typography variant="body1" gutterBottom style={{marginBottom: 20, textAlign: "left"}}>
                        Copy the URL from your websites vehicle listing page, Autotrader, Cycletrader or wherever you&apos;ve listed it.
              </Typography>
              <img src={screenshot} width="100%" alt="Screenshot" />
            </Grid>
          </Hidden>
        </Grid>

      </Container>
    </CommonContainer>
  );
}
