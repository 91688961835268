import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #d5eff8;
  width: 100%;
  padding: 25px 0;
`;
export const FeatureTitle = styled.h3`
  text-align: center;
  margin: 10px 20px 10px 10px;
`;

export const FeatureText = styled.p`
  text-align: center;
  margin: 10px 20px 10px 10px;
  font-size: 14px;
`;
