import {initializeApp} from "firebase/app";
import {getStorage} from "firebase/storage";
import {getAuth} from "firebase/auth";
import {getFunctions, connectFunctionsEmulator} from "firebase/functions";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";

/** Use production or local firebase emulator for backend functions */
const LOCAL_RESOURCES = false;
const firebaseConfig = {
  apiKey: "AIzaSyAaHi1nR5rY06RYybQ6za6BWxTAtkldd9o",
  authDomain: "auto-influence-ai.firebaseapp.com",
  projectId: "auto-influence-ai",
  storageBucket: "auto-influence-ai.appspot.com",
  messagingSenderId: "572997736287",
  appId: "1:572997736287:web:04b781bb667b900f5748ea",
  measurementId: "G-T9B56S1B66",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);
// const analytics = getAnalytics(app);
const db = getFirestore(app);

if (LOCAL_RESOURCES) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectFirestoreEmulator(db, "127.0.0.1", "8080");
}
export {auth, app, storage, functions};
