import * as React from "react";
import PropTypes from "prop-types";
import {Paper, Typography, Grid, Box, Container, Rating, Button} from "@mui/material";
import {RatingWrapper, RatingText, HeroImage} from "./styles";
import heroImage from "../../../assets/home_hero.png";
import {useNavigate} from "react-router-dom";
import {recordEvent} from "../../../classes/analytics";

/**
 * HeroFeature component.
 *
 * @component
 * @return {JSX.Element} Landing component.
 */
function HeroFeature() {
  const navigate = useNavigate();
  return (
    <Paper
      sx={{
        position: "relative",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundImage: `url(${post.image})`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {/* {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}*/}
      <Container maxWidth="lg" sx={{pl: {xs: 0}}}>
        <Grid container sx={{pl: {xs: 0}}}>
          <Grid item md={6}>
            <Box
              sx={{
                position: "relative",
                p: {xs: 3, md: 6},
                pl: {md: 3},
                pr: {md: 0},
              }}
            >
              <Typography align="left" component="h1" variant="h3" color="#1b145d" gutterBottom>
                  Dealers, Meet Your Unfair Advantage in Lead Generation
              </Typography>
              {/* <Typography align="left" variant="body1" color="inherit" paragraph>*/}
              {/*    AI doesn&apos;t know the exhilarating feeling of a V8 or the smell of a new car.*/}
              {/*    But here&apos;s the kicker: it’s surprisingly good at cranking out catchy and engaging content.*/}
              {/* </Typography>*/}
              <Typography align="left" variant="body1" color="inherit" paragraph>
                  Generate social media and marketing assets for your dealership in seconds using AI. Increase engagement and sales
                  with machine learning tailored to automotive and powersport sales.
              </Typography>
              <RatingWrapper>
                <Rating name="size-medium" defaultValue={5} /><RatingText>Used by 1000s of happy dealerships</RatingText>
              </RatingWrapper>
              <Box sx={{my: {xs: 2}}}>
                <Button onClick={() => {
                  recordEvent("Free Trial CTA");
                  navigate("/create");
                }} variant="contained">
                 TRY IT NOW FOR FREE
                </Button>
                <Typography style={{marginTop: 5}} align="left" variant="body2" color="inherit" paragraph>
                      No credit card required
                </Typography>
              </Box>

            </Box>
          </Grid>
          <Grid item md={6}>
            <HeroImage src={heroImage} />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}

HeroFeature.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default HeroFeature;
