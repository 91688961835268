import {getAnalytics, logEvent} from "firebase/analytics";

/**
 * Records an event in Firebase Analytics.
 *
 * @function recordEvent
 * @param {string} event - The name of the event to log.
 *
 * @requires firebase/analytics
 *
 * @example
 * recordEvent('login');
 */
function recordEvent(event) {
  const analytics = getAnalytics();
  logEvent(analytics, event);
}

/**
 * Records a screen view event in Firebase Analytics.
 *
 * @function recordScreenView
 * @param {string} screenName - The name of the screen to log as viewed.
 *
 * @requires firebase/analytics
 *
 * @example
 * recordScreenView('home');
 */
function recordScreenView(screenName) {
  const analytics = getAnalytics();
  logEvent(analytics, "page_view", {
    firebase_screen: screenName, // <- In my case I do not want to include search params, so 'location.pathname' is just what I want
    firebase_screen_class: "firebase-routes-analytics",
  });
}

export {
  recordEvent,
  recordScreenView,
};
