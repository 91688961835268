import React from "react";
import {LoadingModal, LoadingText} from "./styles";
import Lottie from "lottie-react";
import carLoading from "../../../assets/lottie/car_loading.json";
import PropTypes from "prop-types";

/**
 * Loading is a React component that displays a loading animation and a text message.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.text - The text message to display below the loading animation.
 *
 * @example
 * // Use the Loading component
 * <Loading text="Loading..." />
 *
 * @return {ReactElement} Returns a LoadingModal component with a loading animation and a text message.
 *
 * @module components/Loading
 */
export default function Loading({text}) {
  return (
    <LoadingModal>
      {/* <CircularProgress size="8rem" color="primary" />*/}
      <Lottie animationData={carLoading} style={{maxWidth: 400, width: "auto"}} />
      <LoadingText>{text}</LoadingText>
    </LoadingModal>
  );
}

Loading.propTypes = {
  /**
     * The text message to display below the loading animation.
     */
  text: PropTypes.string.isRequired,
};
