import React, {useEffect, useState} from "react";
import Template3 from "../../templates/Template3";
import {useSearchParams} from "react-router-dom";
import {doc, getFirestore, getDoc} from "firebase/firestore";
import {app} from "../../../firebase/clientApp";

// eslint-disable-next-line require-jsdoc
function ManagerPostPreview() {
  try {
    const [searchParams] = useSearchParams();

    const postId = searchParams.get("postId");
    const [data, setData] = useState(null);

    const db = getFirestore(app);

    const getData = async () => {
      const userRef = doc(db, "admin/demos/posts", postId);
      const res = await getDoc(userRef);
      if (res.data()) {
        const {vehicleData} = res.data();
        setData(vehicleData);
        // contains template.id = 1/2/3
      }
    };

    useEffect(() => {
      getData();
    }, []);


    if (!data) {
      return null;
    }

    return (
      <Template3
        mainImage={data?.mainImage}
        make={data?.make}
        model={data?.model}
        price={data?.price}
        vin={data?.vin}
        year={data?.year}
        odometer={data?.mileage}
        stockNumber={data?.stockNumber}
        primaryColor="red"
        financing={true}
      />
    );
  } catch (error) {
    console.log(error);
  }
}

export default ManagerPostPreview;
