import React from "react";
import CommonContainer from "components/common/Layout/Container";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Box,
  Button,
  Typography,
  CardHeader,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import womanCar from "assets/woman-car.jpg";
import carSale from "assets/carsale.jpg";

// eslint-disable-next-line valid-jsdoc
/**
 * Create Component.
 *
 * This component presents the user with options on what kind of post they would like to create.
 * It uses two `Card` components to display two choices: 'Vehicle Listing' and 'Marketing Copy'.
 * Each card has an image, a brief description, and a button.
 *
 * The component uses `react-router-dom`'s `useNavigate` to redirect the user to the corresponding creation page when the card or the button within the card is clicked.
 *
 * The cards are displayed side by side in desktop view (md breakpoint and above), and are stacked vertically in mobile view (below md breakpoint). This responsive design is achieved using the `sx` prop on the `Box` component.
 *
 * @component
 * @example
 *
 * return (
 *   <Create />
 * )
 */
export default function Create() {
  const navigate = useNavigate();
  return (
    <CommonContainer>
      <Typography component="h1" variant="h3" color="#1b145d" gutterBottom>
            What do you need to create?
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2, // spacing between the boxes
          flexDirection: {xs: "column", md: "row"}, // Stack on mobile, horizontal on desktop
        }}
      >
        <Box
          sx={{
            width: {xs: "100%", md: "33%"},
            padding: 1,
          }}
        >
          <Card onClick={() => navigate("/create/vehicle")}>
            <CardHeader title="Vehicle Listing" />
            <CardMedia
              component="img"
              height="194"
              image={carSale}
              alt="Vehicle Listing"
            />
            <CardContent>
              <Typography variant="body1" color="text.secondary">
                        Vehicle sales post. Write a post about a specific vehicle
                        from a VIN number or a website listing.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Button variant="contained" fullWidth>Vehicle Listing</Button>
            </CardActions>
          </Card>
        </Box>

        <Box
          sx={{
            width: {xs: "100%", md: "33%"},
            padding: 1,
          }}

        >
          <Card onClick={() => navigate("/create/marketing")}>
            <CardHeader title="Marketing Copy" />
            <CardMedia
              component="img"
              height="194"
              image={womanCar}
              alt="Promotional Copy"
            />
            <CardContent>

              <Typography variant="body1" color="text.secondary">
                        Write a blog post, email newsletter, a sale, launch, July 4th, Christmas, construct a formal deal letter. Anything!
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Button variant="contained" fullWidth>Marketing Copy</Button>
            </CardActions>
          </Card>
        </Box>
      </Box>
    </CommonContainer>
  );
}
