import React, {useState, useEffect} from "react";
import {TextField, Button, Grid, Box} from "@mui/material";
import {collection, addDoc, getFirestore, doc, onSnapshot} from "firebase/firestore";
import CommonContainer from "../../../../components/common/Layout/Container";
import {app} from "../../../../firebase/clientApp";
import errorhandler from "../../../../classes/errorhandler";

// eslint-disable-next-line require-jsdoc
export default function CreateDemo() {
  const db = getFirestore(app);
  const [contactName, setContactName] = useState("");
  const [name, setName] = useState("");
  const [webAddress, setWebAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [url, setUrl] = useState("");
  const [postId, setPostId] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const docData = {
        dealerData: {
          contactName,
          name,
          webAddress,
          email,
          phoneNumber,
        },
        url,
        templateUrl: `${window.location.origin}/manager/preview`,
        state: 1,
      };
      const docRef = await addDoc(collection(db, "admin/demos/posts"), docData);

      setPostId(docRef.id);
    } catch (error) {
      console.log(error);
    }
  };

  /** Load the Post to edit */
  useEffect(() => {
    if (postId) {
      const userRef = doc(db, "admin/demos/posts", postId);

      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          setData(doc.data());
        } else {
          errorhandler.record(new Error("Unable to load post"));
          setError(`We couldn\'t load that post`);
        }
      }, (error) => {
        errorhandler.record(error);
        setError(`We couldn\'t load that post`);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [db, postId]);

  if (data?.state && data?.state !== 3) {
    return (
      <CommonContainer maxWidth="sm">
        <p>Loading</p>
      </CommonContainer>
    );
  }

  if (data?.state === 3) {
    return (
      <CommonContainer maxWidth="sm">
        <p>Post:</p>
        <div dangerouslySetInnerHTML={{__html: data?.post.replace(/\n/g, "<br />")}} />

        {data?.image && <img src={data.image} />}
      </CommonContainer>
    );
  }

  return (
    <CommonContainer maxWidth="sm">
      {error}
      <form onSubmit={handleSubmit}>
        <Box sx={{mt: 3}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Contact Name"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Dealer Name"
                id="DealerName"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="DealerWebsite"
                label="Dealer Website"
                value={webAddress}
                onChange={(e) => setWebAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="Email"
                label="Dealer Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="PhoneNumber"
                label="Dealer Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="URL to vehicle page"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </CommonContainer>
  );
}
