import React from "react";
import {ImageGrid, StyledImage} from "./styles.js";
import PropTypes from "prop-types";

/**
 * ImageComponent is a functional component that renders a grid of images.
 * When an image is clicked, it calls the handleImageChange function with the selected image URL.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.data - The data for the component.
 * @param {Object} props.data.vehicleData - The vehicle data.
 * @param {string[]} props.data.vehicleData.images - The URLs of the images.
 * @param {string} props.data.vehicleData.mainImage - The URL of the main image.
 * @param {Object} props.data.template - The template data.
 * @param {string} props.data.template.color - The color for the template.
 * @param {function} props.handleImageChange - The function to call when an image is clicked.
 * @return {JSX.Element} A grid of images. Each image is a clickable element that, when clicked,
 * calls the handleImageChange function with the URL of the clicked image. The selected image is
 * highlighted with the color specified in props.data.template.color.
 */
export default function ImageComponent({data, handleImageChange}) {
  return (
    <ImageGrid>
      {data?.vehicleData?.images?.map((image, i) => (
        <StyledImage
          key={`image_${i}`}
          onClick={() => handleImageChange(image.replace(/width=\d+/, `width=960`).replace(/height=\d+/, `height=720`), "mainImage")}
          src={image}
          selected={image === data?.vehicleData.mainImage}
          color={data?.template?.color}
          alt="Vehicle"
        />
      ))}
    </ImageGrid>
  );
};

ImageComponent.propTypes = {
  data: PropTypes.shape({
    vehicleData: PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.string),
      mainImage: PropTypes.string,
    }),
    template: PropTypes.shape({
      color: PropTypes.string,
    }),
  }),
  handleImageChange: PropTypes.func.isRequired,
};
