import styled from "styled-components";
import {Box} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const PostText = styled.div`
  margin-top: 15px;
  font-size: 18px;
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 30px;
  padding: 12px 12px;
  align-self: flex-end;
  border-radius: 10px;
  background-color: ${(props) =>
          props.role === "user" ? "#FCF1B9FF" : "#b9e6fc"};
  
`;

export const Scroller = styled(Box)`
  * {
    overflow-anchor: none;
  }
`;

export const Anchor = styled.div`
  overflow-anchor: auto;
  height: 1px;
`;

export const CopyToClipboard = styled(FontAwesomeIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: black;
  font-size: 22px;
`;

export const ResponseWrapper = styled.div`
  padding: 0 25px 0 0;
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const DownloadIcon = styled.a`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 5px;
  right: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
`;

export const DownloadButton = styled(FontAwesomeIcon)`
  font-size: 25px;
`;

export const Attribution = styled.span`
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  color: #efefef;
  opacity: 1;
  
  a {
    color: white;
  }
`;
