import React, {useState, useEffect} from "react";
import {
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  ImageList,
  ImageListItem,
  Chip,
  Box,
  Slider,
  Hidden,
  FormControlLabel, Checkbox, CircularProgress,
  useMediaQuery,
} from "@mui/material";
import useChatStream from "../../hooks/useChatStream";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase/clientApp";
import {useDealerSelection} from "../../hooks/useDealerSelection";
import CommonContainer from "components/common/Layout/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWandMagicSparkles, faCopy, faSliders} from "@fortawesome/pro-light-svg-icons";
import {faDownload} from "@fortawesome/pro-solid-svg-icons";
import PredictionScore from "components/common/PredictionScore";
import {Attribution, CopyToClipboard, ImageWrapper, DownloadIcon, DownloadButton, ResponseWrapper} from "./styles";
import {animateScroll as scroll} from "react-scroll";

const ideas = [
  {
    title: "Marketing Copy",
    prompt: "Write marketing copy for a flyer about our summer sale",
    length: 50,
    temperature: 50,
  },
  {
    title: "Buyer Photo Caption",
    prompt: "We sold a 2020 Mazda Miata to a girl named Stacey, caption the photo",
    length: 50,
    temperature: 50,
  },
  {
    title: "Email Newsletter",
    prompt: "Write a long newsletter about our Thanksgiving sale",
    length: 100,
    temperature: 50,
  },
  {
    title: "Blog Post",
    prompt: "Write a long blog post about the release of the new Ford Mustang",
    length: 100,
    temperature: 50,
  },
  {
    title: "Facebook Post",
    prompt: "Facebook explaining we'll be closed on Christmas Day",
    length: 50,
    temperature: 50,
  },
  {
    title: "Formal Deal Letter",
    prompt: "Write a formal deal letter to a customer named John, outlining the out the door price of a 2018 BMW X5",
    length: 100,
    temperature: 0,
  },
];

const marks = [
  {
    value: 0,
    label: "Formal",
  },
  {
    value: 50,
    label: "Friendly",
  },
  {
    value: 100,
    label: "Creative",
  },
];

const LengthMarks = [
  {
    value: 0,
    label: "Short",
  },
  {
    value: 50,
    label: "Average",
  },
  {
    value: 100,
    label: "Long",
  },
];

/**
 * copyToClipboard is a function that copies the provided text to the clipboard.
 * @param {string} text - The text to be copied to the clipboard.
 * @returns {Promise<void>} A Promise that resolves once the text is copied to the clipboard.
 */

const copyToClipboard = async (text) => {
  await navigator.clipboard.writeText(text);

  // Alert the copied text
  alert("Copied to clipboard");
};

/**
 * ChatBot is a component that renders a chat interface for generating post ideas.
 * @return {JSX.Element} A chat interface with options for generating post ideas.
 */
function ChatBot() {
  const [currentMessage, setCurrentMessage] = useState("");
  const [assistantMessage, setAssistantMessage] = useState(null);
  const [lastAssistantMessage, setLastAssistantMessage] = useState(null);
  const [charCount, setCharCount] = useState(0);
  const [temperature, setTemperature] = useState(50);
  const [length, setLength] = useState(50);
  const [useEmojis, setUseEmojis] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    sendMessage,
    messages,
    chatLoading,
    isStreaming,
  } = useChatStream();

  const [images, setImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const {dealers, selectedDealer, handleChange} = useDealerSelection();


  const onSendMessage = () => {
    setLoading(true);
    scroll.scrollTo(800);
    setImages([]);
    setLastAssistantMessage(null);
    sendMessage(currentMessage, selectedDealer, length, temperature, useEmojis);
  };


  useEffect(() => {
    if (messages.length % 2 === 0 && !isStreaming) {
      const lastMessage = messages.reverse().find((message) => message?.role === "assistant");
      if (lastMessage?.role === "assistant") {
        getImages(lastMessage.content);
        setLastAssistantMessage(lastMessage.content);
        // engagementScore
      }
    }

    if (messages.length > 0) {
      const lastAssistantMessage = messages.reverse().find((message) => message?.role === "assistant");
      setAssistantMessage(lastAssistantMessage);
      setLoading(false);
    }
  }, [isStreaming, messages]);

  const getImages = async (prompt) => {
    try {
      setLoadingImages(true);
      const func = httpsCallable(functions, "stockImages");
      const {data} = await func({prompt: prompt});
      setImages(data);
      setLoadingImages(false);
    } catch (error) {
      console.log(error);
      setLoadingImages(false);
    }
  };

  const setExampleIdea = (index) => {
    const {prompt, length, temperature} = ideas[index];
    setLength(length);
    setCurrentMessage(prompt);
    setTemperature(temperature);
  };
  return (
    <CommonContainer>
      <Grid container spacing={3}>
        <Hidden mdUp>
          <Grid item xs={12} md={4}>
            <Paper sx={{mt: 1, p: 3}} style={{position: "relative"}}>
              <FontAwesomeIcon size="xl" icon={faSliders} style={{position: "absolute", top: 10, right: 10}} />
              <Box sx={{p: 2}}>
                <Slider
                  aria-label="Temperature"
                  defaultValue={50}
                  step={50}
                  value={temperature}
                  valueLabelDisplay="off"
                  marks={marks}
                  onChange={(e, value) => setTemperature(value)}
                />
              </Box>
              <Box sx={{px: 2}}>
                <Slider
                  aria-label="Copy length"
                  defaultValue={length}
                  step={50}
                  value={length}
                  valueLabelDisplay="off"
                  marks={LengthMarks}
                  onChange={(e, value) => setLength(value)}
                />
              </Box>
              <Box sx={{p: 0}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setUseEmojis(e.target.checked)}
                      defaultChecked
                      checked={useEmojis}
                      sx={{"& .MuiSvgIcon-root": {fontSize: 28}}}
                    />
                  }
                  label="Use Emojis"
                  sx={{mt: 3}}
                />
              </Box>
            </Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8}>
          <Paper sx={{mt: 1, p: 3}}>
            <Grid container spacing={3} component="form" onSubmit={(e) => {
              e.preventDefault(); onSendMessage();
            }}>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{p: {xs: 1}, pb: {xs: 2}}}>
                  Got a topic in mind you need marketing words for? The more info you give us,
                  the better! Don&apos;t forget to tell us what it&apos;s for – like a Facebook post, blog,
                  website blurb, picture caption, or email blast. Anything!
                </Typography>
                <TextField
                  fullWidth
                  rows={8}
                  label="This post is about..."
                  value={currentMessage}
                  size="small"
                  multiline
                  onChange={(e) => {
                    setCurrentMessage(e.target.value);
                    setCharCount(e.target.value.length);
                  }}
                  disabled={chatLoading || isStreaming}
                  helperText={`${charCount}/250`}
                  inputProps={{
                    maxLength: 250,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSendMessage}
                  disabled={chatLoading || isStreaming}
                  type="submit"
                  startIcon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
                >
                        Generate
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{p: {xs: 1}}}>
                  Try some examples:
                </Typography>
                {ideas.map((idea, i) =>
                  <Chip style={{margin: 5, fontWeight: "bold"}} onClick={() => setExampleIdea(i)} key={`idea_${i}`} label={idea.title} color="info" /> )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12} md={4}>
            <Paper sx={{mt: 1, p: 3}} style={{position: "relative"}}>
              <FontAwesomeIcon size="xl" icon={faSliders} style={{position: "absolute", top: 10, right: 10}} />
              <Box sx={{p: 2}}>
                <Slider
                  aria-label="Temperature"
                  defaultValue={50}
                  step={50}
                  value={temperature}
                  valueLabelDisplay="off"
                  marks={marks}
                  onChange={(e, value) => setTemperature(value)}
                />
              </Box>
              <Box sx={{px: 2}}>
                <Slider
                  aria-label="Copy length"
                  defaultValue={length}
                  step={50}
                  value={length}
                  valueLabelDisplay="off"
                  marks={LengthMarks}
                  onChange={(e, value) => setLength(value)}
                />
              </Box>
              <Box sx={{p: 0}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setUseEmojis(e.target.checked)}
                      defaultChecked
                      checked={useEmojis}
                      sx={{"& .MuiSvgIcon-root": {fontSize: 28}}}
                    />
                  }
                  label="Use Emojis"
                  sx={{mt: 3}}
                />
              </Box>
            </Paper>
          </Grid>
        </Hidden>
      </Grid>
      <div>
        {(loading || assistantMessage) &&
          <Paper sx={{mt: 2, p: 3}}>
            {loading && <Box container
              style={{textAlign: "center"}}>
              <Typography variant="subtitle2" sx={{p: {xs: 1}}}>
                Working
              </Typography>
              <CircularProgress size="2.4rem" color="success" />
            </Box>}
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                {assistantMessage && <Paper sx={{mt: 2, p: 3}} style={{position: "relative"}} elevation="8">
                  {lastAssistantMessage && <CopyToClipboard icon={faCopy} onClick={() => copyToClipboard(lastAssistantMessage)} />}

                  <ResponseWrapper>
                    {assistantMessage?.content?.split("\n").map((text, i) =>
                      <React.Fragment key={i}>
                        {text}
                        <br/>
                      </React.Fragment>,
                    )}
                  </ResponseWrapper>
                </Paper>}
              </Grid>

              {lastAssistantMessage && <Grid item xs={12} md={4}>
                <Paper sx={{mt: 2, p: 3}}>
                  <PredictionScore post={lastAssistantMessage} />
                </Paper>
              </Grid>}
            </Grid>
          </Paper>
        }
      </div>

      <Grid container xs={12} sx={{mt: 4}}>
        {loadingImages && <Typography>Loading</Typography>}
        {images.length > 0 && <Typography align="center" component="h1" variant="h4" color="#1b145d" gutterBottom>
          Related Stock Images
        </Typography>}
        <ImageList variant="masonry" cols={useMediaQuery("(min-width:600px)") ? 3 : 1} gap={8}>
          {images.map((item, i) => (
            <ImageListItem key={`${i}`}>
              <ImageWrapper>
                <img
                  src={`${item?.urls?.small}`}
                  srcSet={`${item?.urls?.small}`}
                  loading="lazy"
                />
                <DownloadIcon target="_blank" href={item?.links?.download}>
                  <DownloadButton icon={faDownload} color="white" />
                </DownloadIcon>
                <Attribution>
                  Photo by <a target="_blank" href={`https://unsplash.com/@${item.user.username}?utm_source=autoinfluence&utm_medium=referral`} rel="noreferrer">{item.user.first_name} {item.user.last_name}</a> on <a href="https://unsplash.com/@anniespratt?utm_source=your_app_name&utm_medium=referral">Unsplash</a>
                </Attribution>
              </ImageWrapper>
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </CommonContainer>
  );
}

export default ChatBot;
