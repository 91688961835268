// hooks/useWebSocket.js
import {useEffect, useRef, useState, useContext} from "react";
import {AuthContext} from "../context/auth-context";
import {getFirestore, setDoc, doc, addDoc, collection} from "firebase/firestore";

// const wsUrl = process.env.NODE_ENV === 'development'
//   ? 'ws://192.168.86.250:8000/chat'
//   : 'wss://cloud-run-z2eegzhdca-uw.a.run.app/chat';

const wsUrl = "wss://bot-ws.autoinfluence.ai/chat";

const updateDatabase = async (user, messageId, temperature, messages) => {
  try {
    const db = getFirestore();

    if (messageId) {
      const docRef = doc(db, "users", user.uid, "streamed_chat", messageId);
      await setDoc(docRef,
          {
            timestamp: new Date(),
            streamed: true,
            temperature,
            messages: [...messages],
          },
          {merge: true},
      );

      return docRef.id;
    } else {
      const docRef = await addDoc(collection(db, "users", user.uid, "streamed_chat"), {
        timestamp: new Date(),
        streamed: true,
        temperature,
        messages: [...messages],
      });

      return docRef.id;
    }
  } catch (e) {
    console.log(e);
  }
};

const useChatStream = () => {
  const socketRef = useRef(null);
  const {user} = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [currentInput, setCurrentInput] = useState("");
  const [messageId, setMessageId] = useState(null);
  const [chatLoading, setChatLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);

  const sendMessage = async (input, dealerId, length, temperature, useEmojis) => {
    setChatLoading(true);
    const ws = new WebSocket(wsUrl);
    try {
      ws.addEventListener("open", async () => {
        try {
          messages.push({
            content: input,
            role: "user",
            meta: {timestamp: new Date(), string: input},
          });
          setMessages(messages);

          // Send the uid and text as a JSON payload when the connection is established
          ws.send(
              JSON.stringify({
                uid: user.uid,
                dealerId,
                messages,
                temperature,
                length,
                useEmojis,
              }),
          );

          setCurrentInput("");
          setChatLoading(true);
          setIsStreaming(true);
        } catch (error) {
          console.log(error);
        }
      });

      socketRef.current = ws;

      ws.onmessage = async (event) => {
        try {
          if (!event.data) {
            console.error("Error: Received empty or invalid data");
            setChatLoading(false);
            setIsStreaming(false);
            return;
          }

          const message = JSON.parse(event.data);

          if (message?.type === "rateLimit") {
            messages.push({
              content: "Monthly limit reached. Tap to upgrade to pro.",
              role: "system",
            });
            ws.close();
            setChatLoading(false);
            setIsStreaming(false);
          }

          if (message?.type === "error") {
            messages.push({
              content:
                "There was a problem with that request. Maybe check your network?",
              role: "system",
            });
            ws.close();
            setChatLoading(false);
            setIsStreaming(false);
          }

          if (message?.type === "done") {
            const docID = await updateDatabase(
                user,
                messageId,
                temperature,
                messages,
            );
            setMessageId(docID);
            ws.close();
            setChatLoading(false);
            setIsStreaming(false);
            setMessages([]);
            return;
          }
          // If the last message is from the server, append the received character

          if (!message?.error && message?.type === "mainMessage") {
            if (
              messages.length > 0 &&
              messages[messages.length - 1].role === "assistant"
            ) {
              const newMessages = [...messages];
              newMessages[newMessages.length - 1].content += message.content;
              setMessages(newMessages);
            } else {
              // If it is the first character received, create a new message
              messages.push({
                content: message.content,
                role: "assistant",
              });
              setMessages(messages);
              setChatLoading(false);
            }
          }
        } catch (error) {
          console.log("error", error);
          // messages.push({
          //   content:
          //     'We had a problem sending that message. Check your internet connection and try again.',
          //   role: 'system',
          // });
          // setMessages(messages);
        } finally {
          setChatLoading(false);
        }
      };

      ws.onclose = (msg) => {
        setChatLoading(false);
        setIsStreaming(false);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        messages.push({
          content:
            "We had a problem sending that message. Check your internet connection and try again.",
          role: "system",
        });
        setMessages(messages);
        setChatLoading(false);
      };
    } catch (error) {
      console.error("Error occurred while connecting to WebSocket:", error);
      messages.push({
        content:
          "We had a problem sending that message. Check your internet connection and try again.",
        role: "system",
      });
      setMessages(messages);
      setChatLoading(false);
      ws.close();
    }
  };

  const stopFetchData = () => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      setIsStreaming(false);
    }
  };

  useEffect(() => {
    return () => {
      stopFetchData();
    };
  }, []);

  return {sendMessage, stopFetchData, messages, chatLoading, isStreaming};
};

export default useChatStream;
