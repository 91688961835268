import React from "react";
import CommonContainer from "../../Layout/Container";

/**
 * Privacy is a React component that displays the Privacy Policy of the website.
 *
 * @function
 *
 * @example
 * // Use the Terms component
 * <Terms />
 *
 * @return {ReactElement} Returns a layout structure displaying the terms of use of the website.
 *
 * @module components/Privacy
 */
export default function Privacy() {
  return (
    <CommonContainer landing>
      <div>
        <h1>Privacy Policy for Auto Influence</h1>

        <p>Last updated: [Insert last updated date]</p>

        <h2>1. Introduction</h2>

        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p>Auto Influence ("us", "we", or "our") operates www.autoinfluence.ai (hereinafter referred to as
          {/* eslint-disable-next-line react/no-unescaped-entities */}
                    "Service").</p>

        <p>Our Privacy Policy governs your visit to www.autoinfluence.ai, and explains how we collect, safeguard
                    and disclose information that results from your use of our Service.</p>

        <p>We use your data to provide and improve Service. By using Service, you agree to the collection and
                    use of information in accordance with this policy.</p>

        <h2>2. Information Collection and Use</h2>

        <p>We collect several different types of information for various purposes to provide and improve our
                    Service to you.</p>

        <h3>Types of Data Collected</h3>

        <h3>Personal Data</h3>

        <p>While using our Service, we may ask you to provide us with certain personally identifiable
                    information that can be used to contact or identify you. Personally identifiable information may
                    include, but is not limited to: email address.</p>

        <h3>Usage Data</h3>

        <p>We may also collect information on how the Service is accessed and used. This Usage Data may include
          {/* eslint-disable-next-line react/no-unescaped-entities */}
                    information such as your computer's Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that you visit, the time and date of your visit, the time
                    spent on those pages, and other diagnostic data.</p>

        <h2>3. Use of Data</h2>

        <p>Auto Influence uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our Service</li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>

        <h2>4. Privacy of your data</h2>

        <p>We value your trust in providing us your Personal Data, thus we are striving to use commercially
                    acceptable means of protecting it. But remember that no method of transmission over the internet, or
                    method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute
                    security.</p>

        <h2>5. Contact Us</h2>

        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at
                    hello@autoinfluence.ai.</p>
      </div>
    </CommonContainer>
  );
}


