import React, {useEffect, useState, useContext, createContext} from "react";
import {collection, query, onSnapshot, where} from "firebase/firestore";
import {AuthContext} from "./auth-context";
import {getFirestore} from "firebase/firestore";
import PropTypes from "prop-types";

export const SubscriptionContext = createContext();

/**
 * SubscriptionProvider is a React component that provides subscription context to its child components.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The child components of SubscriptionProvider.
 *
 * @example
 * // Use the SubscriptionProvider component
 * <SubscriptionProvider>
 *   <ChildComponent />
 * </SubscriptionProvider>
 *
 * @return {ReactElement} Returns a SubscriptionContext.Provider component that wraps the children.
 *
 * @module components/SubscriptionProvider
 */
export function SubscriptionProvider({children}) {
  const {user} = useContext(AuthContext);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loadingSubscriptionStatus, setLoadingSubscriptionStatus] = useState(true);

  useEffect(() => {
    if (user) {
      const db = getFirestore();
      const subscriptionRef = collection(db, `customers/${user.uid}/subscriptions/`);
      const q = query(subscriptionRef, where("status", "in", ["active"]));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
          const subscription = snapshot.docs[0].data();
          setSubscriptionStatus(subscription.status);
          const {name} = subscription.items[0].price.product;
          setSubscription({
            ...snapshot.docs[0].data(),
            name,
          });
          setLoadingSubscriptionStatus(false);
        } else {
          setSubscriptionStatus(null);
          setLoadingSubscriptionStatus(false);
        }
      });

      return () => unsubscribe(); // cleanup on unmount
    }
  }, [user]);

  return (
    <SubscriptionContext.Provider value={{subscriptionStatus, loadingSubscriptionStatus, subscription}}>
      {children}
    </SubscriptionContext.Provider>
  );
}

SubscriptionProvider.propTypes = {
  /**
   * The child components of SubscriptionProvider.
   */
  children: PropTypes.node.isRequired,
};
