import React from "react";
import {
  Container,
  CssBaseline,
} from "@mui/material";
import {Paper} from "./styles";
import Header from "../../../Dashboard/Header";
import LandingHeader from "../../../Landing/Header";
import Footer from "../../Footer";
import OnBoarding from "../../../OnBoarding";
import PropTypes from "prop-types";

/**
 * CommonContainer is a React component that provides a common layout structure for pages.
 * It includes a header, footer, and an optional onboarding section.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The child components to render within the container.
 * @param {boolean} [props.landing=false] - A flag to indicate if the current page is a landing page.
 *
 * @example
 * // Use the CommonContainer component
 * <CommonContainer landing={true}>
 *   <p>Hello, world!</p>
 * </CommonContainer>
 *
 * @return {ReactElement} Returns a layout structure with a header, footer, and an optional onboarding section.
 *
 * @module components/CommonContainer
 */
export default function CommonContainer({children, landing = false}) {
  return (
    <Container maxWidth={false} disableGutters>
      <CssBaseline />
      {landing ? <LandingHeader /> : <Header />}
      <Container maxWidth="lg">

        <Paper style={{backgroundColor: "#f9f9f9", minHeight: 500}} variant="outlined" sx={{my: {xs: 3, md: 3}, p: {xs: 1, md: 2}}}>
          {children}
        </Paper>
      </Container>
      <Footer />
      {!landing && <OnBoarding />}
    </Container>
  );
};

CommonContainer.propTypes = {
  /**
     * The child components to render within the container.
     */
  children: PropTypes.node.isRequired,

  /**
     * A flag to indicate if the current page is a landing page.
     */
  landing: PropTypes.bool,
};

CommonContainer.defaultProps = {
  landing: false,
};
