// CarInfo.js
import React from "react";
import {
  StyledBody,
  ImageContainer,
  PriceBanner,
  Price,
  InfoBar,
  Year,
  YMM,
  DataText,
  StyledContent,
  DataIcon,
  Image,
  FooterText,
} from "./styles";

import {faGaugeHigh, faCalendar, faCogs, faCar} from "@fortawesome/pro-solid-svg-icons";
import {Grid} from "@mui/material";
import PropTypes from "prop-types";

/**
 * Template is a React component that displays information about a car.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.year - The year of the car.
 * @param {string} props.make - The make of the car.
 * @param {string} props.model - The model of the car.
 * @param {string} props.price - The price of the car.
 * @param {string} props.mainImage - The URL of the main image of the car.
 * @param {string} props.odometer - The odometer reading of the car.
 * @param {string} props.vin - The VIN of the car.
 * @param {string} props.stockNumber - The stock number of the car.
 * @param {string} props.primaryColor - The primary color to use for the UI.
 * @param {boolean} props.financing - Whether financing is available for the car.
 *
 * @example
 * // Use the Template component
 * <Template year="2020" make="Toyota" model="Corolla" price="$20,000" mainImage="https://example.com/image.jpg" odometer="20000" vin="1HGCM82633A123456" stockNumber="123456" primaryColor="#000000" financing={true} />
 *
 * @return {ReactElement} Returns a layout structure displaying information about a car.
 *
 * @module components/Template
 */
const Template = ({
  year,
  make,
  model,
  price,
  mainImage,
  odometer,
  vin,
  stockNumber,
  primaryColor,
  financing,
}) => (
  <StyledBody>
    <ImageContainer>

      <Image crossorigin src={mainImage} alt={`${year} ${make} ${model}`} width="512"/>

    </ImageContainer>
    <StyledContent>
      <Grid container spacing={2} justifySelf="center">
        {year && <Grid item xs={12} justifyContent="flex-start">
          <DataIcon size="lg" icon={faCalendar} color="#000" /><DataText>{year}</DataText>
        </Grid>}
        {odometer && <Grid item xs={12}>
          <DataIcon size="lg" icon={faGaugeHigh} color="#000" /><DataText>{odometer}</DataText>
        </Grid>}
        {stockNumber &&
                <Grid item xs={12}>
                  <DataIcon size="lg" icon={faCar} color="#000" /><DataText>Stock #: {stockNumber}</DataText>
                </Grid>}
        {vin && <Grid item xs={12}>
          <DataIcon size="lg" icon={faCogs} color="#000" /><DataText>VIN: {vin}</DataText>
        </Grid>}
      </Grid>
    </StyledContent>

    <InfoBar primaryColor={primaryColor}>
      <Year>{`${year}`}</Year>
      <YMM>{`${make}`}</YMM>
      <YMM>{`${model}`}</YMM>
      <PriceBanner>
        <Price>{price}</Price>
      </PriceBanner>

      {financing && <FooterText>Financing Available</FooterText>}
    </InfoBar>
  </StyledBody>
);

Template.propTypes = {
  year: PropTypes.string.isRequired,
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  mainImage: PropTypes.string.isRequired,
  odometer: PropTypes.string.isRequired,
  vin: PropTypes.string.isRequired,
  stockNumber: PropTypes.string.isRequired,
  primaryColor: PropTypes.string.isRequired,
  financing: PropTypes.bool.isRequired,
};

export default Template;

