import React, {useEffect, useState} from "react";
import {Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Grid} from "@mui/material";

import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {useAuth} from "../../context/auth-context";
import {getFirestore} from "firebase/firestore";
import {app} from "../../firebase/clientApp";
import {useNavigate} from "react-router-dom";
import {getStripePayments, getProducts} from "@stripe/firestore-stripe-payments";
import ErrorHandler from "../../classes/errorhandler";
import {collection, onSnapshot, doc, setDoc} from "firebase/firestore";

/**
 * Pricing is a React component that displays the pricing tiers of the website.
 *
 * @function
 *
 * @example
 * // Use the Pricing component
 * <Pricing />
 *
 * @return {ReactElement} Returns a layout structure displaying the pricing tiers of the website.
 *
 * @module components/Pricing
 */
function Pricing() {
  try {
    const {user} = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [billingInterval, setBillingInterval] = useState("month");

    const tiers = [
      {
        title: "Startup",
        description: [
          "10 Posts",
          "1 Dealership",
          "3 Templates",
          "Email support",
        ],
        buttonText: "Get Started",
        buttonVariant: "outlined",
      },
      {
        title: "Enterprise",
        subheader: "Most popular",
        description: [
          "50 Posts a Month",
          "1 Dealership",
          "Premium Templates",
          "Priority email support",
        ],
        buttonText: "Get Started",
        buttonVariant: "contained",
      },
      {
        title: "Commercial",
        description: [
          "100 Posts a Month",
          "5 Dealerships",
          "Premium Templates",
          "Phone & email support",
        ],
        buttonText: "Get Started",
        buttonVariant: "outlined",
      },
    ];

    const startCheckout = async (priceId) => {
      if (!user) {
        navigate("/create");
        return;
      }

      const sessions = collection(getFirestore(app), `customers/${user.uid}/checkout_sessions`);

      const d = doc(sessions);
      await setDoc(d, {
        price: priceId,
        success_url: `${window.location.origin}/create`,
        cancel_url: `${window.location.origin}/`,
        allow_promotion_codes: true,
        trial_from_plan: false,
      });

      onSnapshot(d, (snap) => {
        const {error, url} = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.

          // ga.event({
          //     action: 'begin_checkout',
          //     params: {
          //         event_callback: function () {
          //             window.location.href = url;
          //         }}
          // })

          window.location.assign(url);
        }
      });
    };

    const getActiveProduct = (title, interval = billingInterval) => {
      const p = products[title];

      const {prices, name} = p;

      const pr = prices.filter((item) => item.active && item.interval === interval);

      // eslint-disable-next-line camelcase
      const {id, unit_amount} = pr[0];

      // eslint-disable-next-line camelcase
      let price = (unit_amount / 100).toFixed(2);

      // If billing is annual, calculate average monthly cost
      if (interval === "year") {
        price = (price / 12).toFixed(2);
      }

      return {
        id,
        price,
        name,
      };
    };

    useEffect(() => {
      setLoading(true);

      const payments = getStripePayments(app, {
        productsCollection: "products",
        customersCollection: "customers",
      });

      getProducts(payments, {
        includePrices: true,
        activeOnly: true,
      })
          .then((products) => {
            const productsObj = products.reduce((acc, product) => {
              acc[product.name] = product;
              return acc;
            }, {});
            setProducts(productsObj);
            setLoading(false);
          })
          .catch((error) => ErrorHandler.record(error));
    }, []);

    if (loading || products.length === 0) {
      return null;
    }

    return (
      <>
        {/* Hero unit */}
        <Container disableGutters maxWidth="md" component="main" sx={{p: 8}}>
          <Typography
            component="h1"
            variant="h3"
            align="center"
            color="text.primary"
            gutterBottom
          >
                        Start Your Journey
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" component="p">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
                        We understand that every dealer, both big and small, has unique needs. That's why we've designed
                        our services to cater to everyone. If you need something a little more customized, please
                        contact us.
          </Typography>
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main">
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mb: 2}}>
            <ButtonGroup variant="contained">
              <Button
                onClick={() => setBillingInterval("month")}
                variant={billingInterval === "month" ? "contained" : "outlined"}
              >
                                Monthly
              </Button>
              <Button
                onClick={() => setBillingInterval("year")}
                variant={billingInterval === "year" ? "contained" : "outlined"}
              >
                                Annually
              </Button>
            </ButtonGroup>
          </Box>
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => {
              const activeProduct = getActiveProduct(tier.title);
              // Enterprise card is full width at sm breakpoint
              return (<Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={activeProduct.name}
                    subheader={tier.subheader}
                    titleTypographyProps={{align: "center"}}
                    action={tier.title === "Pro" ? <StarIcon/> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                                                        theme.palette.mode === "light" ?
                                                            theme.palette.grey[200] :
                                                            theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary">
                                                        ${activeProduct.price}

                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                                                        /mo
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="left"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() => startCheckout(activeProduct.id)}
                      fullWidth
                      variant={tier.buttonVariant}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              );
            },
            )}

          </Grid>
        </Container>

      </>
    );
  } catch (error) {
    return null;
  }
}

export default Pricing;
