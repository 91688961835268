import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Wrapper = styled.div`
  position: relative;
`;

export const ScoreWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const Title = styled.h3`
  font-weight: normal;
  margin: 0;
  font-family: Plus Jakarta Sans, ui-sans-serif, system-ui, -apple-system,Segoe UI, Roboto, Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont
`;

export const ScaleWrapper = styled.div`
  position: relative;
  margin: 35px auto;
  width: 250px;
`;

export const Scale = styled.div`
  width: 100%;
  border: 1px solid #0288d1;
  height: 8px;
  position: relative;
  border-top: 0;
  border-bottom: 0;
  
  &:after {
    top: 3px;
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    background-color: #0288d1;
  }
`;

export const LeftScaleLabel = styled.p`
  left: -15px;
  bottom: -24px;
  position: absolute;
  font-size: 0.6875rem;
  margin-top: 16px;
  font-weight: 500;
  line-height: 0.8125rem;
`;


export const RightScaleLabel = styled.p`
  right: -12px;
  bottom: -24px;
  position: absolute;
  font-size: 0.6875rem;
  margin-top: 16px;
  font-weight: 500;
  line-height: 0.8125rem;
`;

export const ScaleIndicator = styled(FontAwesomeIcon)`
  color: #0288d1;
  position: absolute;
  left: ${(props) => props.score && `${props.score - 3}%`};
  bottom: 8px;
  font-size: 22px;
`;
