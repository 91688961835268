import {useEffect, useState, useContext} from "react";
import {collection, query, onSnapshot, where, getDocs, doc, getDoc} from "firebase/firestore";
import {AuthContext} from "../context/auth-context";
import {getFirestore} from "firebase/firestore";

/**
 * useRateLimit is a custom hook that checks the rate limits for the current user.
 * It checks the user's subscription status and calculates the remaining posts and dealers they can create.
 * It also checks if the user has access to premium templates.
 *
 * @function
 * @example
 * // Use the useRateLimit hook
 * const {canCreatePost, canCreateDealer, canUsePremiumTemplates, remainingPosts, loadingRateLimit} = useRateLimit();
 *
 * @return {Object} An object containing the following properties:
 * - canCreatePost: A boolean indicating whether the user can create a new post.
 * - canCreateDealer: A boolean indicating whether the user can create a new dealer.
 * - canUsePremiumTemplates: A boolean indicating whether the user has access to premium templates.
 * - remainingPosts: The number of posts the user can still create.
 * - loadingRateLimit: A boolean indicating whether the rate limit data is still loading.
 *
 * @module hooks/useRateLimit
 */
export default function useRateLimit() {
  const {user} = useContext(AuthContext);
  const [loadingRateLimit, setLoadingRateLimit] = useState(true);
  const [canCreatePost, setCanCreatePost] = useState(true);
  const [canCreateDealer, setCanCreateDealer] = useState(true);
  const [canUsePremiumTemplates, setCanUsePremiumTemplates] = useState(false);
  const [remainingPosts, setRemainingPosts] = useState(null);

  useEffect(() => {
    const checkRateLimit = async () => {
      if (user) {
        try {
          const db = getFirestore();

          // Get user's subscriptions
          const subscriptionRef = collection(db, `customers/${user.uid}/subscriptions`);
          const subscriptionSnap = await getDocs(subscriptionRef);

          let activeSubscription;
          subscriptionSnap.forEach((doc) => {
            if (doc.data().status === "active") {
              activeSubscription = doc.data();
            }
          });

          if (activeSubscription) {
            // Get rate limit for the user's subscription tier
            const planName = activeSubscription.items[0].price.product.name;
            const rateLimitRef = doc(db, `rateLimits/${planName.toLowerCase()}`);
            const rateLimitSnap = await getDoc(rateLimitRef);
            const rateLimitData = rateLimitSnap.data();

            // Check premium templates access
            setCanUsePremiumTemplates(rateLimitData.premiumTemplates);

            // Check post count
            const start = activeSubscription.current_period_start;
            const end = activeSubscription.current_period_end;
            const postsRef = collection(db, `users/${user.uid}/posts`);
            const postsQuery = query(postsRef,
                where("timestamp", ">=", start),
                where("timestamp", "<=", end));

            // Here we use onSnapshot to create a real-time listener
            onSnapshot(postsQuery, (postsSnap) => {
              if (postsSnap.size >= rateLimitData.posts) {
                setCanCreatePost(false);
              }

              // Set remaining posts
              setRemainingPosts(rateLimitData.posts - postsSnap.size);
            });

            // Check dealer count
            const dealersRef = collection(db, `users/${user.uid}/dealers`);

            // We do the same for the dealers
            onSnapshot(dealersRef, (dealersSnap) => {
              if (dealersSnap.size >= rateLimitData.dealers) {
                setCanCreateDealer(false);
              }
            });
          } else {
            // User has no active subscription. Enforce hard limits.

            // No access to premium templates
            setCanUsePremiumTemplates(false);

            // Hard limit of 5 posts
            const postsRef = collection(db, `users/${user.uid}/posts`);
            const postsSnap = await getDocs(postsRef);

            if (postsSnap.size >= 5) { // limit set to 5 posts
              setCanCreatePost(false);
            }
            setRemainingPosts(5 - postsSnap.size); // remaining posts

            // Hard limit of 1 dealer
            const dealersRef = collection(db, `users/${user.uid}/dealers`);
            const dealersSnap = await getDocs(dealersRef);

            if (dealersSnap.size >= 1) { // limit set to 1 dealer
              setCanCreateDealer(false);
            }
          }

          setLoadingRateLimit(false);
        } catch (error) {
          console.log(error);
        }
      }
    };

    checkRateLimit();
  }, [user]);

  return {canCreatePost, canCreateDealer, canUsePremiumTemplates, remainingPosts, loadingRateLimit};
}
