import React, {useState} from "react";
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import {Container, TextField, Box, Button, Typography, Snackbar, Alert} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/system";
import logoImg from "../../../assets/logo.png";

const BackgroundBox = styled(Box)({
  backgroundColor: "#eff5fa",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const WhiteBox = styled(Box)({
  backgroundColor: "#ffffff",
  padding: "20px",
  borderRadius: "10px",
  marginTop: "25px",
  width: "400px",
});

/**
 * Email is a React component that provides a login interface for the user.
 * It provides a form for signing in with email.
 * @component
 * @example
 * // Render the Login component
 * <EmailAuth />
 *
 * @return {React.Element} The rendered Login component
 *
 * @module components/Email
 */
function EmailAuth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignUp = () => {
    if (password !== confirmPassword) {
      showError("Passwords do not match. Please try again.");
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          // Send email verification after sign up
          sendEmailVerification(user)
              .then(() => {
                showSuccess("Verification email sent. Please check your inbox.");
                navigate("/auth/verify-email"); // navigate to verification page
              })
              .catch((error) => {
                showError(getErrorMessage(error.code));
              });
        })
        .catch((error) => {
          showError(getErrorMessage(error.code));
        });
  };

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          if (user.emailVerified) {
            navigate("/create");
          } else {
            // Navigate to a route like '/auth/verify-email' or show a message to the user.
            showError("Please verify your email before logging in.");
            navigate("/auth/verify-email");
          }
        })
        .catch((error) => {
          showError(getErrorMessage(error.code));
        });
  };

  const getErrorMessage = (code) => {
    switch (code) {
      // add as many case as you want
      case "auth/wrong-password":
      case "auth/user-not-found":
        return "Email or password not recognized.";
      default:
        return "An error occurred. Please try again.";
    }
  };

  const showError = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
  };

  const showSuccess = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <BackgroundBox component="main" maxWidth="xs">
      <img src={logoImg} />
      <WhiteBox>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "top", horizontal: "center"}}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{width: "100%"}}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Container>
          <Typography variant="h5" align="center" color="text.secondary" component="h5">
            {isLogin ? "Log in" : "Sign up"}
          </Typography>
          <TextField
            fullWidth
            size="small"
            label="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{mt: 2}}
          />
          <TextField
            fullWidth
            size="small"
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{mt: 2}}
          />
          {!isLogin && (
            <TextField
              fullWidth
              type="password"
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{mt: 2}}
            />
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={isLogin ? handleSignIn : handleSignUp}
            sx={{mt: 3, mb: 2}}
          >
            {isLogin ? "Sign In" : "Sign Up"}
          </Button>
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? "Create new account" : "Back to login"}
          </Button>
        </Container>
      </WhiteBox>

    </BackgroundBox>
  );
}

export default EmailAuth;


