import React, {useState} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography, Slider, FormControlLabel, Checkbox,
} from "@mui/material";
import PropTypes from "prop-types";

const marks = [
  {
    value: 0,
    label: "Formal",
  },
  {
    value: 50,
    label: "Friendly",
  },
  {
    value: 100,
    label: "Creative",
  },
];

const LengthMarks = [
  {
    value: 0,
    label: "Short",
  },
  {
    value: 50,
    label: "Average",
  },
  {
    value: 100,
    label: "Long",
  },
];

/**
 * TemperatureDialog is a React component that renders a dialog for setting the temperature, length, and emoji usage for a post.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.open - Whether the dialog is open.
 * @param {Function} props.handleClose - The function to call when the dialog should be closed.
 * @param {Function} props.handleSave - The function to call when the settings should be saved.
 * @param {Object} props.gptConfig - The initial configuration for the post.
 * @param {number} props.gptConfig.temperature - The initial temperature setting for the post.
 * @param {number} props.gptConfig.length - The initial length setting for the post.
 * @param {boolean} props.gptConfig.useEmojis - The initial emoji usage setting for the post.
 *
 * @example
 * // Use the TemperatureDialog component
 * <TemperatureDialog open={open} handleClose={handleClose} handleSave={handleSave} gptConfig={gptConfig} />
 *
 * @return {ReactElement} Returns a Dialog component with controls for setting the temperature, length, and emoji usage for a post.
 *
 * @module components/TemperatureDialog
 */
export default function TemperatureDialog({open, handleClose, handleSave, gptConfig}) {
  const {temperature: initialTemperature, length: initialLength, useEmojis: initialUseEmojis} = gptConfig;
  const [temperature, setTemperature] = useState(initialTemperature);
  const [length, setLength] = useState(initialLength);
  const [useEmojis, setUseEmojis] = useState(initialUseEmojis);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
                Regenerate Text
      </DialogTitle>
      <DialogContent>

        <Box sx={{px: 5}}>
          <Typography variant="subtitle1" gutterBottom sx={{mt: 3}}>
                        Set the tone that the AI will use for your post
          </Typography>
          <Slider
            aria-label="Custom marks"
            defaultValue={temperature}
            step={50}
            valueLabelDisplay="auto"
            marks={marks}
            onChange={(e, value) => setTemperature(value)}
          />
        </Box>

        <Box sx={{px: 5}}>
          <Typography variant="subtitle1" gutterBottom sx={{mt: 3}}>
                        Set the length
          </Typography>
          <Slider
            aria-label="Custom marks"
            defaultValue={length}
            step={50}
            valueLabelDisplay="auto"
            marks={LengthMarks}
            onChange={(e, value) => setLength(value)}
          />
        </Box>

        <Box sx={{p: 0}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={useEmojis}
                onChange={(e) => setUseEmojis(e.target.checked)}
                defaultChecked
                sx={{"& .MuiSvgIcon-root": {fontSize: 28}}}
              />
            }
            label="Use Emojis"
            sx={{mt: 3}}
          />
        </Box>


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleSave(temperature, length, useEmojis )} variant="contained" autoFocus>
                    Regenerate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TemperatureDialog.propTypes = {
  /**
   * Whether the dialog is open.
   */
  open: PropTypes.bool.isRequired,

  /**
   * The function to call when the dialog should be closed.
   */
  handleClose: PropTypes.func.isRequired,

  /**
   * The function to call when the settings should be saved.
   */
  handleSave: PropTypes.func.isRequired,

  /**
   * The initial configuration for the post.
   */
  gptConfig: PropTypes.shape({
    /**
     * The initial temperature setting for the post.
     */
    temperature: PropTypes.number.isRequired,

    /**
     * The initial length setting for the post.
     */
    length: PropTypes.number.isRequired,

    /**
     * The initial emoji usage setting for the post.
     */
    useEmojis: PropTypes.bool.isRequired,
  }).isRequired,
};
