import {
  collection,
  getFirestore,
} from "firebase/firestore";

import {app} from "../firebase/clientApp";

/**
 * The firestoreDB class provides access to Firestore database operations.
 * It includes methods for retrieving user-related data.
 *
 * @class
 *
 * @requires firebase/firestore
 * @requires firebase/clientApp
 */
class firestoreDB {
  /**
     * getUser retrieves a reference to the user's collection in Firestore.
     * @method
     *
     * @param {string} uid - The ID of the user.
     *
     * @return {CollectionReference} A reference to the 'users' collection in Firestore.
     */
  getUser(uid) {
    return collection(getFirestore(app), `users`);
  }

  // getEpisodes = (uid) => collection(getFirestore(app),`users/${uid}/episodes`)
}

// eslint-disable-next-line new-cap
export default new firestoreDB;
