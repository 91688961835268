import * as React from "react";
import PropTypes from "prop-types";
import {Container, Grid, Typography} from "@mui/material";
import {List, Logo, StyledContainer, Link} from "./styles";
import logoImg from "../../../assets/logo.png";

/**
 * Copyright is a React component that displays copyright information.
 *
 * @function
 *
 * @example
 * // Use the Copyright component
 * <Copyright />
 *
 * @return {ReactElement} Returns a layout structure displaying copyright information.
 *
 * @module components/Copyright
 */
function Copyright() {
  return (
    <>
      <Typography variant="h6" align="left" gutterBottom>
        <Logo src={logoImg} />
      </Typography>
      <Typography variant="body2" color="text.secondary" align="left">
        {"Copyright © "}
        <Link color="inherit" to="/">
                Auto Influence
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}<br />San Diego, California.
      </Typography>
    </>
  );
}

const footers = [
  {
    title: "Company",
    description: ["About Us", "Contact us", "FAQ"],
  },

  {
    title: "Legal",
    description: ["Privacy policy", "Terms of Use"],
  },
];

/**
 * Footer is a React component that displays a footer with various information.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.description - The description to display in the footer.
 * @param {string} props.title - The title to display in the footer.
 *
 * @example
 * // Use the Footer component
 * <Footer description="Some description" title="Some title" />
 *
 * @return {ReactElement} Returns a layout structure displaying a footer.
 *
 * @module components/Footer
 */
function Footer() {
  return (
    <StyledContainer
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [3, 6],
      }}
      maxWidth={false}

    >
      <Container maxWidth="lg">


        <Grid container >
          <Grid item md={3} alignItems="left" justify="left">
            <Copyright />
          </Grid>
          <Grid item md={8} container spacing={4} justifyContent="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  {footer.title}
                </Typography>
                <List>
                  {footer.description.map((item, i) => (
                    <li key={`${item}_${i}`}>
                      <Link to={`/${item.toLowerCase().replace(/ /g, "-")}`} variant="subtitle1" color="text.secondary">
                        {item}
                      </Link>
                    </li>
                  ))}
                </List>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </StyledContainer>
  );
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;
