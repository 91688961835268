import React, {useEffect, useState} from "react";
import {
  Button,
  Step,
  StepLabel,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  CssBaseline,
} from "@mui/material";
import {getFirestore, doc, collection, getDocs, addDoc} from "firebase/firestore";
import {useAuth} from "../../context/auth-context";
import {app} from "../../firebase/clientApp";
import {StyledContainer, StyledStepper, StyledGrid} from "./styles";

/**
 * Returns an array of step labels for the onboarding process.
 *
 * @return {string[]} Array of step labels.
 */
function getSteps() {
  return ["Welcome", "Details", "Feedback"];
}

/**
 * OnBoarding component.
 *
 * @component
 * @return {JSX.Element | null} OnBoarding component.
 */
const OnBoarding = () => {
  const {user} = useAuth();
  const db = getFirestore(app);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [hasDealers, setHasDealers] = useState(true);
  const [dealerInfo, setDealerInfo] = useState({
    name: "",
    webAddress: "",
    phoneNumber: "",
    emailAddress: "",
    financingAvailable: false,
  });
  const [nameError, setNameError] = useState("");

  // Check if the dealers collection exists for this user
  useEffect(() => {
    const fetchData = async () => {
      const dealersRef = collection(doc(db, "users", user.uid), "dealers");
      const dealerSnapshot = await getDocs(dealersRef);
      setHasDealers(!dealerSnapshot.empty);
    };

    fetchData();
  }, [db, user.uid]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    setDealerInfo({...dealerInfo, [event.target.name]: event.target.value});
  };

  const handleCheckboxChange = (event) => {
    setDealerInfo({...dealerInfo, [event.target.name]: event.target.checked});
  };

  if (hasDealers) {
    return null;
  }

  return (
    <StyledContainer>
      <CssBaseline />
      <StyledStepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </StyledStepper>
      <StyledGrid container spacing={2} justifyContent="center" alignItems="center">
        {activeStep === 0 && (
          <Grid item xs={12}>
            <Typography variant="h5">Welcome to AutoInfluence!</Typography>
            <Typography variant="body1">Here&apos;s how it works:</Typography>
            <Box m={2}>
              <Typography variant="body1">Step 1: Paste in a link to a vehicle listing.</Typography>
              <Typography variant="body1">Step 2: Generate a social media post.</Typography>
              <Typography variant="body1">Step 3: Pick a template and post to your favorite social platform.</Typography>
            </Box>
          </Grid>
        )}
        {activeStep === 1 && (
          <>
            <Typography variant="h5">These details will be used in your social media posts</Typography>
            <Grid item xs={12} sm={6}>
              <TextField required fullWidth label="Dealer Name" variant="outlined" name="name" value={dealerInfo.name} error={nameError.length > 0} helperText={nameError} onChange={handleChange}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Web Address" variant="outlined" name="webAddress" value={dealerInfo.webAddress} onChange={handleChange}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Phone Number" variant="outlined" name="phoneNumber" value={dealerInfo.phoneNumber} onChange={handleChange}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Email Address" variant="outlined" name="emailAddress" value={dealerInfo.emailAddress} onChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={<Checkbox name="financingAvailable" checked={dealerInfo.financingAvailable} onChange={handleCheckboxChange}/>} label="Do you provide financing?" />
            </Grid>
          </>
        )}
        {activeStep === 2 && (
          <Grid item xs={12}>
            <Typography variant="body1">We&apos;re constantly working to improve AutoInfluence. If you have any feedback or feature requests, please let us know at sales@autoinfluence.ai</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
            <Button disabled={activeStep === 0} onClick={handleBack} color="primary">
                        Back
            </Button>
            <Box sx={{flex: "1 1 auto"}} />
            <Button onClick={handleNext} color="primary">
              {activeStep === steps.length - 1 ?
                            "Finish" :
                            "Next"}
            </Button>
          </Box>
        </Grid>
      </StyledGrid>
    </StyledContainer>
  );

  /**
   * Handles the next button click event.
   */
  function handleNext() {
    if (activeStep === 1) {
      // Validate the dealer name
      if (dealerInfo.name.length < 2) {
        setNameError("Dealer name should be 2 or more characters");
        return;
      }
    }
    if (activeStep === 2) {
      // Save dealerInfo to Firestore
      const userRef = doc(db, "users", user.uid);
      const dealersCollection = collection(userRef, "dealers");
      addDoc(dealersCollection, dealerInfo);
      setHasDealers(true);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
};

export default OnBoarding;
