import React, {createContext, useContext, useState} from "react";
import {Alert, Snackbar} from "@mui/material";
import PropTypes from "prop-types";

/**
 * GlobalStateContext is a context object that provides global state values and functions.
 * Components can subscribe to changes in the global state by consuming this context.
 */
export const GlobalStateContext = createContext();

/**
 * GlobalStateProvider is a provider component that wraps around other components.
 * It provides the global state values and functions to its child components.
 * @param {object} children - The child components that will consume the global state.
 * @return {JSX.Element} A provider component that provides the global state to its child components.
 */
export const GlobalStateProvider = ({children}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  /**
   * showError is a function that displays an error message in a snackbar.
   * @param {string} message - The error message to be displayed.
   */
  const showError = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
  };

  /**
   * showSuccess is a function that displays a success message in a snackbar.
   * @param {string} message - The success message to be displayed.
   */
  const showSuccess = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  /**
   * handleCloseSnackbar is a function that handles the closing of the snackbar.
   * @param {object} event - The event object.
   * @param {string} reason - The reason for closing the snackbar.
   */
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <GlobalStateContext.Provider value={{
      isLoading,
      setIsLoading,
      showDialog,
      showError,
      showSuccess,
      setShowDialog,
    }}>
      {children}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{width: "100%"}}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </GlobalStateContext.Provider>
  );
};

GlobalStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * useGlobalState is a custom hook that allows components to access the global state values and functions.
 * @return {object} An object containing the global state values and functions.
 */
export const useGlobalState = () => useContext(GlobalStateContext);
