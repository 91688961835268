import React from "react";
import CommonContainer from "../../Layout/Container";

/**
 * Terms is a React component that displays the terms of use of the website.
 *
 * @function
 *
 * @example
 * // Use the Terms component
 * <Terms />
 *
 * @return {ReactElement} Returns a layout structure displaying the terms of use of the website.
 *
 * @module components/Terms
 */
export default function Terms() {
  return (
    <CommonContainer landing>
      <div>
        <h1>Terms of Use</h1>

        <p>Last updated: 07/01/2023</p>

        <h2>1. Agreement to Terms</h2>

        {/* eslint-disable-next-line max-len,react/no-unescaped-entities */}
        <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Auto Influence ("we," "us" or "our"), concerning your access to and use of the www.autoinfluence.ai website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the "Site").</p>

        <h2>2. User Representations</h2>

        {/* eslint-disable-next-line max-len */}
        <p>By using our Site, you represent and warrant that: all registration information you submit will be true, accurate, current, and complete; you will maintain the accuracy of such information and promptly update such information as necessary.</p>

        <h2>3. User Registration</h2>

        {/* eslint-disable-next-line max-len */}
        <p>You are required to register with us in order to use our Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>

        <h2>4. Prohibited Activities</h2>

        {/* eslint-disable-next-line max-len */}
        <p>You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>

        <h2>5. Site Management</h2>

        {/* eslint-disable-next-line max-len */}
        <p>We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use.</p>

        <h2>6. Privacy Policy</h2>

        {/* eslint-disable-next-line max-len */}
        <p>We care about data privacy and security. Please review our Privacy Policy posted on the Site. By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use.</p>

        <h2>7. Contact Us</h2>

        <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:</p>

        <p>Auto Influence<br/>
                    1275-D Auto Park Way #174<br/>
                    Escondido, California, 92029<br/>
                    Email: hello@autoinfluence.ai</p>
      </div>
    </CommonContainer>
  );
}
