import React from "react";
import CommonContainer from "../../Layout/Container";
import {
  Box,
  Grid,
} from "@mui/material";

/**
 * Contact is a React component that displays the contact info of the website.
 *
 * @function
 *
 * @example
 * // Use the Terms component
 * <Contact />
 *
 * @return {ReactElement} Returns a layout structure displaying the terms of use of the website.
 *
 * @module components/FAQ
 */
export default function Contact() {
  return (
    <CommonContainer landing>
      <h1>Contact Us</h1>

      <Grid container xs={12}>
        <Grid item xs={8}>
          <Box>

            <p>
                At Auto Influence, we&apos;re revolutionizing the way
              dealerships operate. We are eager to help you take your business
              to the next level with our state-of-the-art AI solutions.
            </p>

            <p>
            Do you have questions, suggestions, or feedback? We’d love to hear from you. Your comments are important to us and we read every message we receive.
            </p>

            <p>
            Whether it’s a simple question or a valuable suggestion, we are here 24/7. <br />
              <a href="mailto:info@autoinfluence.ai">info@autoinfluence.ai</a>.
            </p>

          </Box>
        </Grid>
      </Grid>
    </CommonContainer>
  );
}
