import React, {useState, useEffect} from "react";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase/clientApp";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {faLocationPin} from "@fortawesome/pro-solid-svg-icons";
import "react-circular-progressbar/dist/styles.css";
import {Skeleton} from "@mui/material";
import PropTypes from "prop-types";
import {
  ScoreWrapper,
  Title,
  Wrapper,
  ScaleWrapper,
  Scale,
  LeftScaleLabel,
  RightScaleLabel,
  ScaleIndicator,
} from "./styles";

/**
 * PredictionScore is a React component that fetches and displays the predicted engagement score for a post.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.post - The post for which to fetch and display the engagement score.
 *
 * @example
 * // Use the PredictionScore component
 * <PredictionScore post={post} />
 *
 * @return {ReactElement} Returns a Wrapper component with the engagement score and related information, or null if no post is provided.
 *
 * @module components/PredictionScore
 */
export default function PredictionScore({post}) {
  const [engagementScore, setEngagementScore] = useState(null);
  const [loading, setLoading] = useState(false);

  const getEngagementScore = async (post) => {
    try {
      setLoading(true);
      const func = httpsCallable(functions, "engagementScore");
      const {data} = await func({post});
      setEngagementScore(data);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (post) {
      getEngagementScore(post);
    } else {
      setEngagementScore(null);
    }
  }, [post]);

  const calculateColor = (engagementScore) => {
    const {prediction} = engagementScore;

    if (prediction < 60) {
      return "#a80000";
    }

    if (prediction < 70) {
      return "#ffae00";
    }

    return "#3eb13e";
  };

  if (loading) {
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height={40} />
        <br />
        <Skeleton variant="rectangular" width="100%" height={40} />
        <br />
        <Skeleton variant="rectangular" width="100%" height={40} />
        <br />
        <Skeleton variant="rectangular" width="100%" height={40} />
        <br />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </>
    );
  }

  if (engagementScore) {
    return <Wrapper>

      <Title>Engagement</Title>
      <p>Age {engagementScore.ageRange}</p>

      <ScaleWrapper>
        <ScaleIndicator score={engagementScore.prediction} icon={faLocationPin} />
        <LeftScaleLabel>Female</LeftScaleLabel>
        <Scale />
        <RightScaleLabel>Male</RightScaleLabel>
      </ScaleWrapper>

      <ScoreWrapper style={{width: 60, height: 60}}>
        <CircularProgressbar
          value={engagementScore.prediction}
          text={engagementScore.prediction}
          styles={buildStyles({
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",

            // Text size
            textSize: "38px",
            textColor: "#000000",
            // Colors
            pathColor: calculateColor(engagementScore),

            trailColor: "#d6d6d6",
            backgroundColor: "#000",
          })}
        />
      </ScoreWrapper>
      <p>{engagementScore.summary}</p>
    </Wrapper>;
  }

  return null;
}

PredictionScore.propTypes = {
  /**
   * The post for which to fetch and display the engagement score.
   */
  post: PropTypes.object.isRequired,
};
