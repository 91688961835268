import styled from "styled-components";

export const LoadingModal = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const LoadingText = styled.h2`
  color: white;
  font-weight: normal;
`;
