import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {getFirestore, doc, getDoc} from "firebase/firestore";
import {app} from "../../../firebase/clientApp";
import {useAuth} from "../../../context/auth-context";
import {Grid} from "@mui/material";
import {ActionHeader, Image} from "./styles";
import {PostText} from "../../../components/GeneratePost/CreatePost/styles";
import {CustomCard, Icon, ActionText} from "./styles";
import {faDownload, faCopy} from "@fortawesome/pro-light-svg-icons";
import Base64Downloader from "react-base64-downloader";
import CommonContainer from "../../../components/common/Layout/Container";
import {useGlobalState} from "../../../context/state-context";
import errorhandler from "../../../classes/errorhandler";

/**
 * useQuery is a custom hook that parses the query string of the current URL.
 * It uses the useLocation hook from react-router-dom to get the current location object,
 * and then creates a new URLSearchParams object with the search string from the location.
 *
 * @return {URLSearchParams} An instance of URLSearchParams initialized with the current query string
 *
 * @example
 * const query = useQuery();
 * const value = query.get("param");
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams|URLSearchParams}
 */
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * ViewPost is a React component that displays a specific post based on a job ID.
 * It allows the user to download the post image as a base64 string and copy the post text to the clipboard.
 *
 * @component
 * @example
 * // Render the ViewPost component
 * <ViewPost />
 *
 * @return {React.Element} The rendered ViewPost component
 *
 * @module components/ViewPost
 */
function ViewPost() {
  const query = useQuery();
  const jobId = query.get("jobid");
  const [post, setPost] = useState(null);
  const {user} = useAuth();
  const db = getFirestore(app);
  const {showError} = useGlobalState();

  useEffect(() => {
    const fetchPost = async () => {
      const userRef = doc(db, "users", user.uid);
      const docRef = doc(userRef, "posts", jobId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPost(docSnap.data());
      } else {
        errorhandler.record(new Error("Unable to load document > Finalized Post"));
        showError(`There was a problem archiving this post`);
      }
    };

    if (jobId) {
      fetchPost();
    }
  }, [db, jobId, user.uid]);

  const {vehicleData} = post || {};

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);

    // Alert the copied text
    alert("Copied to clipboard");
  };

  return (
    <CommonContainer>
      <Grid container spacing={4} >
        {post ? (
                <>
                  <Grid item md={6}>
                    <CustomCard>
                      <Base64Downloader
                        base64={`data:image/png;base64,${post.base64}`}
                        downloadName={`autoinfluence_${vehicleData.year}-${vehicleData.make}-${vehicleData.model}`}
                        Tag="span"
                        className="my-class-name"
                        style={{color: "orange"}}
                        onDownloadSuccess={() => console.log("File download initiated")}
                        onDownloadError={() => console.warn("Download failed to start")}
                      >
                        <ActionHeader>
                          <Icon icon={faDownload} />
                          <ActionText>DOWNLOAD</ActionText>
                        </ActionHeader>
                      </Base64Downloader>
                      <Image
                        src={`data:image/png;base64,${post.base64}`}
                        alt=""
                      />
                    </CustomCard>
                  </Grid>
                  <Grid item md={6}>
                    <CustomCard style={{paddingBottom: 15}}>
                      <ActionHeader onClick={() => copyToClipboard(post?.post)}>
                        <Icon icon={faCopy} />
                        <ActionText>COPY</ActionText>
                      </ActionHeader>
                      <PostText dangerouslySetInnerHTML={{__html: post?.post.replace(/\n/g, "<br />")}} />
                    </CustomCard>
                  </Grid>
                </>
            ) : (
                <p>Loading...</p>
            )}
      </Grid>
    </CommonContainer>
  );
}

export default ViewPost;
