import styled from "styled-components";
import {Link as ScrollLink} from "react-scroll";
export const Title = styled.h1`
  color: red;
  font-size: 38px;
  font-family: Plus Jakarta Sans,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,"Noto Sans","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
`;

export const Logo = styled.img`

`;

export const Menu = styled.ul`
  flex-grow: 1
`;

export const MenuItem = styled.li`
  float: left;
  margin: 0 15px;
`;

export const MenuLink = styled(ScrollLink)`
  color: black;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    color: gray;
  }
`;

export const ButtonWrapper = styled.div`
  float: right;
`;
