import React from "react";
import {Container, Hidden} from "@mui/material";
import Header from "./Header";
import Footer from "../common/Footer";
import HeroFeature from "./HeroFeature";
import Feature1 from "./Feature1";
import Pricing from "../Pricing";
import SamplePosts from "./SamplePosts";
import {PromoBanner, PromoText, PromoIcon, PromoWrapper, InnerWrapper} from "./styles";
import {faCheck, faStar} from "@fortawesome/pro-solid-svg-icons";

const mainFeaturedPost = {
  title: "Title of a longer featured blog post",
  description:
        "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
  image: "https://source.unsplash.com/random?wallpapers",
  imageText: "main image description",
  linkText: "Continue reading…",
};

/**
 * Landing component.
 *
 * @component
 * @return {JSX.Element} Landing component.
 */
function Landing() {
  return (
    <>
      <Container maxWidth={false} disableGutters>
        <Hidden mdDown>
          <PromoBanner>
            <Container maxWidth="lg" disableGutters>
              <InnerWrapper>
                <PromoWrapper>
                  <PromoIcon icon={faCheck} />
                  <PromoText>
                                Founded in San Diego, CA
                  </PromoText>
                </PromoWrapper>

                <PromoWrapper>
                  <PromoIcon icon={faCheck} />
                  <PromoText>
                                Operated by Dealers
                  </PromoText>
                </PromoWrapper>

                <PromoWrapper>
                  <PromoIcon icon={faStar} />
                  <PromoText>1000&apos;s of happy customers</PromoText>
                </PromoWrapper>
              </InnerWrapper>
            </Container>
          </PromoBanner>
        </Hidden>
        <Header />
        <main>
          <HeroFeature post={mainFeaturedPost} />
          <a id="how-it-works" />
          <Feature1 />
          <a id="styles" />
          <SamplePosts />
          <a id="pricing" />
          <Pricing />
        </main>
      </Container>
      <Footer />
    </>
  );
}

export default Landing;
