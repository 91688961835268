// hooks/useWebSocket.js
import {useEffect, useRef, useState, useContext} from "react";
import {AuthContext} from "../context/auth-context";
import {getFirestore, setDoc, doc} from "firebase/firestore";

// const wsUrl = process.env.NODE_ENV === 'development'
//   ? 'ws://192.168.86.250:8000/generate_post'
//   : 'wss://cloud-run-z2eegzhdca-uw.a.run.app/generate_post';

const wsUrl = "wss://bot-ws.autoinfluence.ai/generate_post"; // 'ws://192.168.86.250:8000/generate_post';

const updateDatabase = async (user, messageId, messages) => {
  try {
    const content = messages[messages.length - 1].content;
    const db = getFirestore();

    const docRef = doc(db, "users", user.uid, "posts", messageId);
    await setDoc(docRef,
        {
          post: content,
          state: 4,
          stateText: "Finalizing",
        },
        {merge: true},
    );

    return docRef.id;
  } catch (e) {
    console.log(e);
  }
};

const useCompletionStream = () => {
  const socketRef = useRef(null);
  const {user} = useContext(AuthContext);
  const [completionMessages, setCompletionMessages] = useState([]);
  const [completionLoading, setCompletionLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);

  const generatePost = async (postId) => {
    setCompletionLoading(true);

    const ws = new WebSocket(wsUrl);
    try {
      ws.addEventListener("open", async () => {
        try {
          // Send the uid and text as a JSON payload when the connection is established
          ws.send(
              JSON.stringify({uid: user.uid, postId}),
          );
          setCompletionLoading(true);
          setIsStreaming(true);
        } catch (error) {
          console.log(error);
        }
      });

      socketRef.current = ws;

      ws.onmessage = async (event) => {
        try {
          if (!event.data) {
            console.error("Error: Received empty or invalid data");
            setCompletionLoading(false);
            setIsStreaming(false);
            return;
          }

          const message = JSON.parse(event.data);

          if (message?.type === "error") {
            completionMessages.push({
              content:
                "There was a problem with that request. Maybe check your network?",
              role: "system",
            });
            ws.close();
            setCompletionLoading(false);
            setIsStreaming(false);
          }

          if (message?.type === "done") {
            ws.close();
            setCompletionLoading(false);
            setIsStreaming(false);
            await updateDatabase(user, postId, completionMessages);
            setCompletionMessages([]);
            return;
          }
          // If the last message is from the server, append the received character

          if (!message?.error && message?.type === "mainMessage") {
            if (
              completionMessages.length > 0
            ) {
              const newMessages = [...completionMessages];
              newMessages[newMessages.length - 1].content += message.content;
              setCompletionMessages(newMessages);
            } else {
              // If it is the first character received, create a new message
              completionMessages.push({
                content: message.content,
              });
              setCompletionMessages(completionMessages);
            }
          }
        } catch (error) {
          console.log("error", error);
          // messages.push({
          //   content:
          //     'We had a problem sending that message. Check your internet connection and try again.',
          //   role: 'system',
          // });
          // setMessages(messages);
        } finally {
          setCompletionLoading(false);
        }
      };

      ws.onclose = (msg) => {
        setCompletionLoading(false);
        setIsStreaming(false);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        completionMessages.push({
          content:
            "We had a problem sending that message. Check your internet connection and try again.",
          role: "system",
        });
        setCompletionMessages(completionMessages);
        setCompletionLoading(false);
      };
    } catch (error) {
      console.error("Error occurred while connecting to WebSocket:", error);
      completionMessages.push({
        content:
          "We had a problem sending that message. Check your internet connection and try again.",
        role: "system",
      });
      setCompletionMessages(completionMessages);
      setCompletionLoading(false);
      ws.close();
    }
  };

  const stopFetchData = () => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      setIsStreaming(false);
    }
  };

  useEffect(() => {
    return () => {
      stopFetchData();
    };
  }, []);

  return {generatePost, stopFetchData, completionMessages, completionLoading, isStreaming};
};

export default useCompletionStream;
