import React, {useEffect} from "react";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {auth} from "../../../firebase/clientApp";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import logoImg from "../../../assets/logo.png";
import {useAuthState} from "react-firebase-hooks/auth";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/system";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/pro-solid-svg-icons";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
const provider = new GoogleAuthProvider();

const BackgroundBox = styled(Box)({
  backgroundColor: "#eff5fa",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const WhiteBox = styled(Box)({
  backgroundColor: "#ffffff",
  padding: "20px",
  borderRadius: "10px",
  marginTop: "25px",
});

/**
 * Login is a React component that provides a login interface for the user.
 * It provides buttons for signing in with Google or email.
 * If the user is already signed in, they are redirected to the "/create" route.
 *
 * @component
 * @example
 * // Render the Login component
 * <Login />
 *
 * @return {React.Element} The rendered Login component
 *
 * @module components/Login
 */
function Login() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/create");
    }
  }, [user]);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.log(error);
    }

    // signInWithPopup(auth, provider)
    //     .then((result) => {
    //       const credential = GoogleAuthProvider.credentialFromResult(result);
    //       const token = credential.accessToken;
    //       const user = result.user;
    //     })
    //     .catch((error) => {
    //       console.error("Google Sign In Error", error);
    //     });
  };

  return (
    <BackgroundBox component="main" maxWidth="xs" justify="center">
      <img src={logoImg} />
      <WhiteBox>
        <Container>
          <Box
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" align="center" color="text.secondary" component="h5">
                            Log in or sign up in seconds
            </Typography>
            <Typography variant="body2" align="center" color="text.secondary" component="p">
                            Login or create an account to get started
            </Typography>

            <Button
              fullWidth
              variant="contained"
              color="googleBlue"
              onClick={handleGoogleSignIn}
              startIcon={<FontAwesomeIcon icon={faGoogle} />}
              sx={{mt: 3, mb: 1}}
            >
                            Sign In with Google
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate("/auth/email")}
              startIcon={<FontAwesomeIcon icon={faEnvelope} />}
              sx={{mt: 1, mb: 2}}
            >
                            Sign In with Email
            </Button>
          </Box>
        </Container>
      </WhiteBox>
    </BackgroundBox>
  );
}

export default Login;
