import styled from "styled-components";

export const PostContainer = styled.div`
  max-width: 512px;
  justify-content: center;
`;

export const ArrowButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    position: absolute;
    top: 50%;
    color: #000; // Change as needed
    // Make sure to adjust these values to position the buttons correctly
    left: ${(props) => props.direction === "left" ? "0" : "unset"};
    right: ${(props) => props.direction === "right" ? "0" : "unset"};
    // Add other styles as needed...
`;

export const TemplateHeader = styled.div`
  height: 50px;
  text-align: center;
  h2 {
    margin: 0;
    font-weight: normal;
  }
`;
