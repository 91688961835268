import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const StyledBody = styled.div`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  position: relative;
  width: auto;
  //max-width: 512px;
  height: 512px;
  overflow: hidden;
  background-color: #D9D7D1;
  text-align: left;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const PriceBanner = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  text-align: center;
`;

export const Price = styled.span`
  color: white;
  font-size: 40px;
  font-family: Plus Jakarta Sans, sans-serif;
  font-weight: bold;
`;

export const InfoBar = styled.div`
  background-color: ${(props) => props.primaryColor ? props.primaryColor : "#E90226"};;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  bottom: 0;
  left: 15px;
  width: 45%;
  border-bottom: 2px solid ${(props) => props.primaryColor ? props.primaryColor : "#E90226"}
`;

export const YMM = styled.span`
  color: white;
  font-weight: bold;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
`;

export const Year = styled.span`
  color: white;
  font-weight: normal;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 20px;
  line-height: 26px;
`;

export const StyledLogo = styled.img`
  position: absolute;
  right: 15px;
`;

export const StyledContent = styled.div`
  padding: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  margin-left: 240px;
  width: 260px;
`;

export const DataTable = styled.table`
  border-collapse: collapse;
  width: 90%;
  margin: 0;

  th, td {
    border: 0;
    padding: 5px;
    text-align: left;
    font-size: 18px;
  }
`;

export const DataIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  width: 35px;
`;

export const DataText = styled.span`
  font-size: 14px;
  font-family: Plus Jakarta Sans, sans-serif;
`;

export const Image = styled.img`
  object-fit: cover;
  height: 340px;
`;

export const FooterText = styled.span`
  color: white;
  position: absolute;
  bottom: 10px;
  font-family: Plus Jakarta Sans, sans-serif;
`;
