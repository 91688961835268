import styled from "styled-components";

export const RatingWrapper = styled.div`
  display: flex;

  
  flex-direction: row;
`;

export const RatingText = styled.p`
    margin: 0 0 0 10px;
  padding: 0;
`;
export const HeroImage = styled.img`
  width: 100%;
  margin-top: 45px;
`;
