import React, {useEffect, useState} from "react";
import {Grid, Typography, Button, Alert, CardActions} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashCan} from "@fortawesome/pro-light-svg-icons";
import {collection, onSnapshot, doc, deleteDoc, query} from "firebase/firestore";
import {useAuth} from "../../../context/auth-context";
import {app} from "../../../firebase/clientApp";
import {getFirestore} from "firebase/firestore";
import {DealerListWrapper, StyledCard, CardContentWrapper} from "./styles";
import {useNavigate} from "react-router-dom";
import useRateLimit from "../../../hooks/useRateLimit";
import CommonContainer from "../../common/Layout/Container";

const DealershipList = () => {
  const [dealers, setDealers] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const {canCreateDealer} = useRateLimit();
  const {user} = useAuth();
  const db = getFirestore(app);
  const navigate = useNavigate();

  useEffect(() => {
    const userRef = doc(db, "users", user.uid);
    const dealersCollection = collection(userRef, "dealers");
    const dealerQuery = query(dealersCollection);

    const unsubscribe = onSnapshot(dealerQuery, (querySnapshot) => {
      const dealers = querySnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}));
      setDealers(dealers);
    });

    return () => unsubscribe();
  }, [db, user.uid]);

  const handleDelete = async (id) => {
    try {
      if (dealers.length > 1) {
        const dealerRef = doc(db, "users", user.uid, "dealers", id);
        await deleteDoc(dealerRef);
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/dealerships/dealer?dealerId=${id}`);
  };

  const handleAdd = () => {
    if (canCreateDealer) {
      navigate("/dealerships/dealer");
    } else {
      setShowAlert(true);
    }
  };

  return (
    <CommonContainer>
      <Button variant="contained" color="primary" onClick={handleAdd}>Add Dealer</Button>
      {showAlert && !canCreateDealer &&
                <Alert severity="warning" onClose={() => setShowAlert(false)}>
                    Please upgrade your package to add more dealers.
                </Alert>
      }
      {showAlert && canCreateDealer &&
                <Alert severity="success" onClose={() => setShowAlert(false)}>
                    Dealer successfully deleted!
                </Alert>
      }
      <DealerListWrapper>
        <Grid container spacing={3}>
          {dealers.map((dealer, index) => (
            <Grid item xs={12} md={4} key={index}>
              <StyledCard>
                <CardContentWrapper>
                  <Typography variant="h5">{dealer.name}</Typography>
                  <Typography variant="subtitle1">{dealer.webAddress}</Typography>
                  <Typography variant="subtitle2">{dealer.phoneNumber}</Typography>
                  <Typography variant="subtitle2">{dealer.emailAddress}</Typography>
                  <Typography variant="subtitle2">{dealer.howDidYouHear}</Typography>
                  <Typography variant="subtitle2">Financing Available: {dealer.financingAvailable ? "Yes" : "No"}</Typography>


                </CardContentWrapper>

                <CardActions>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => handleEdit(dealer.id)}
                    startIcon={<FontAwesomeIcon
                      icon={faEdit}
                    />}>
                                                Edit
                  </Button>
                  {dealers.length > 1 && <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={() => handleDelete(dealer.id)}
                    startIcon={<FontAwesomeIcon
                      icon={faTrashCan}
                    />}>
                                                Delete
                  </Button>}
                </CardActions>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </DealerListWrapper>
    </CommonContainer>
  );
};

export default DealershipList;
