import styled from "styled-components";
import {Card, CardContent, Paper as StyledPaper} from "@mui/material";

export const DealerListWrapper = styled.div`
  margin-top: 1rem;
`;

export const StyledCard = styled(Card)`
  position: relative;
  padding: 1rem;
`;

export const CardContentWrapper = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Paper = styled(StyledPaper)`
  background-color: #f9f9f9;
`;
