import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const StyledBody = styled.div`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  position: relative;
  //width: 512px;
  height: 512px;
  overflow: hidden;
  background-color: #D9D7D1;
  text-align: left;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const PriceBanner = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  text-align: center;
`;

export const Price = styled.span`
  color: white;
  font-size: 30px;
  font-family: Plus Jakarta Sans, sans-serif;
  font-weight: bold;
  text-align: right;
  margin: 0 15px 0 0;
  position: relative;
`;

export const InfoBar = styled.div`
  background-color: ${(props) => props.primaryColor ? props.primaryColor : "#E90226"};;
  height: 100px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 340px;
  padding-left: 15px;
  padding-right: 25px;
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
`;

export const YMM = styled.span`
  color: white;
  font-weight: bold;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 25px;
  line-height: 28px;
  margin-right: 10px;
`;

export const Mileage = styled.span`
  color: white;
  font-family: Plus Jakarta Sans, sans-serif;
`;

export const Year = styled.span`
  color: white;
  font-weight: bold;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
`;

export const StyledLogo = styled.img`
  position: absolute;
  right: 15px;
`;

export const StyledContent = styled.div`
  position: absolute;
  bottom: 15px;
  height: 80px;
  width: 200px;
  background-color: rgb(129,151,146, 0.8);
  right: 0;
  padding: 15px 0 0 0;
  text-align: right;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
`;

export const DataTable = styled.table`
  border-collapse: collapse;
  width: 90%;
  margin: 0;

  th, td {
    border: 0;
    padding: 5px;
    text-align: left;
    font-size: 18px;
  }
`;

export const DataIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  width: 35px;
`;

export const DataText = styled.span`
  font-size: 14px;
  font-family: Plus Jakarta Sans, sans-serif;
  color: white;
`;

export const Image = styled.img`
  object-fit: cover;
  height: 512px;
`;

export const FooterText = styled.span`
  color: white;
  position: absolute;
  bottom: 10px;
  font-family: Plus Jakarta Sans, sans-serif;
`;
