import React from "react";
import CommonContainer from "../../Layout/Container";

/**
 * Terms is a React component that displays the About Us content of the website.
 *
 * @function
 *
 * @example
 * // Use the Terms component
 * <About />
 *
 * @return {ReactElement} Returns a layout structure displaying the terms of use of the website.
 *
 * @module components/About
 */
export default function About() {
  return (
    <CommonContainer landing={true}>
      <h1>About Auto Influence</h1>

      <p>Located in the heart of Escondido, California, Auto Influence is revolutionizing the way vehicle
                dealerships approach social media. Our state-of-the-art artificial intelligence app empowers dealerships
                to create compelling and engaging social media content assets with ease and efficiency.</p>

      <p>At Auto Influence, we believe that innovation and creativity go hand in hand. Founded on the principle of
                providing practical, cutting-edge solutions for our clients, we are committed to leveraging the power of
                artificial intelligence to streamline marketing processes.</p>

      {/* eslint-disable-next-line max-len,react/no-unescaped-entities */}
      <p>With 20 years of experience in the auto industry under our belts, we aren't just tech enthusiasts — we're dealership owners ourselves. Our intimate understanding of this industry is deeply woven into the fabric of Auto Influence. Our team, a blend of dedicated AI specialists, seasoned engineers, and automotive industry veterans, brings together the best of both worlds: cutting-edge technology and hands-on dealership experience. Drawing from our extensive experience, we work relentlessly to refine and enhance our services, proudly serving as a trusted partner to fellow vehicle dealerships. It's not just about increasing online presence; it's about forging meaningful
      </p>

      <p>We are excited to journey with you in harnessing the power of AI for your social media marketing
                strategies. Welcome to the future of automotive marketing!</p>

      <p>For more information, or if you have any questions, feel free to reach out to us at
                hello@autoinfluence.ai.</p>
    </CommonContainer>
  );
}
