import React, {useEffect, useState} from "react";
import CommonContainer from "../common/Layout/Container";
import {CircularProgress, Paper, Button} from "@mui/material";
import {List, ListItem, Text, CheckMark, Waiting, IconWrapper, Timer, TimerWrapper, TimerTitle} from "./styles";
import {Grid} from "@mui/material";
import {faCheckCircle, faPause} from "@fortawesome/pro-light-svg-icons";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Timer component that counts down from a specified duration.
 * @return {JSX.Element} The rendered Timer component.
 */
const TimerComponent = () => {
  const [timer, setTimer] = useState(60);
  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  return <Timer>{timer}</Timer>;
};

/**
 * Component that displays a loading sequence.
 * @param {Object} props - The component props.
 * @param {number} props.state - The current state of the loading sequence.
 * @return {JSX.Element} The rendered LoadingSequence component.
 */
export default function LoadingSequence({state}) {
  const navigate = useNavigate();

  /**
   * Determines the appropriate icon based on the state.
   * @param {number} i - The current state index.
   * @return {JSX.Element} The rendered icon.
   */
  const IconLogic = ({i}) => {
    return (
      <IconWrapper>
        {i === state && <CircularProgress size="1.2rem" color="success" />}
        {state > i && <CheckMark icon={faCheckCircle} />}
        {state < i && <Waiting icon={faPause} />}
      </IconWrapper>
    );
  };

  IconLogic.propTypes = {
    i: PropTypes.number.isRequired,
  };

  return (
    <CommonContainer>
      <Grid container spacing={3}>
        <Grid item md={8}>
          <Paper>
            {state !== -1 ? <List>
              <ListItem>
                <IconLogic i={0} />
                <Text>Starting</Text>
              </ListItem>
              <ListItem>
                <IconLogic i={1} />
                <Text>Fetching Website</Text></ListItem>
              <ListItem>
                <IconLogic i={2} />
                <Text>Reading Data</Text>
              </ListItem>
              {state === -1 && <ListItem>
                <Text>Error</Text>
              </ListItem>}
            </List> : <List>
              <ListItem>Error loading data</ListItem></List>}
          </Paper>
        </Grid>

        <Grid item md={4}>
          <Paper>
            <TimerWrapper>
              <TimerTitle>Estimated time remaining</TimerTitle>
              <TimerComponent />
              <Button variant="outlined" onClick={() => navigate("/create")}>Start Over</Button>
            </TimerWrapper>
          </Paper>
        </Grid>

      </Grid>
    </CommonContainer>
  );
}

LoadingSequence.propTypes = {
  state: PropTypes.number.isRequired,
};
