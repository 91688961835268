import styled from "styled-components";
import {Container} from "@mui/material";
import {Link as StyledLink} from "react-router-dom";

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
export const Logo = styled.img`

`;

export const StyledContainer = styled(Container)`
  background-color: #fafaf9;
  border-top: 1px #B4B4B4 solid;
  text-align: left;
`;

export const Link = styled(StyledLink)`
  color: black;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline
  }
`;
