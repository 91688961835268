import React from "react";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from "@mui/material";
import PropTypes from "prop-types";
/**
 * ConfirmDialog component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines whether the dialog is open or not.
 * @param {function} props.handleClose - Callback function to handle the close event of the dialog.
 * @param {function} props.handleSave - Callback function to handle the save event of the dialog.
 * @return {JSX.Element} ConfirmDialog component.
 */
const ConfirmDialog = ({open, handleClose, handleSave}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
                Are you finished editing?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
                    Once this post is finalized you won&apos;t be able to make any further edits.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Not Yet</Button>
        <Button onClick={handleSave} variant="contained" autoFocus>
                    Yes!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default ConfirmDialog;
