import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 5px 0;
  padding: 0 25px;
`;

export const PostWrapper = styled.div`
  border: 1px solid #dddfe2;
  border-radius: 8px;
  padding: 12px 0;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  color: #1c1e21;
`;

// Style the Header section
export const PostHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

// Style the Post body section
export const PostBody = styled.div`
  
`;

export const IconWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const PostText = styled.div`
  margin-top: 15px;
  font-size: 16px;
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 26px;
  padding: 0 12px;
  text-align: left;
`;

export const PostTitle = styled.h3`
  margin: 5px 0;
  font-weight: normal;
`;

export const LoadingModal = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const LoadingText = styled.h2`
  color: white;
  font-weight: normal;
`;

export const ColorPickerContainer = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  position: relative;
  border-radius: 100%;
  margin-left: 15px;
`;

export const Palette = styled.div`
  position: absolute;
  top: 40px; // Adjust this as needed
  right: 0; // This will align the palette to the right edge of the ColorPickerContainer
  z-index: 1000;
`;
export const GeneratingPost = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  
  span {
    font-size: 16px;
    font-family: Plus Jakarta Sans, sans-serif;
    margin: 0 0 15px 0;
  }
`;
