import React, {useEffect, useState, useContext} from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  ButtonGroup,
} from "@mui/material";

import StarIcon from "@mui/icons-material/StarBorder";
import CommonContainer from "../../common/Layout/Container";
import {useAuth} from "../../../context/auth-context";
import {getFirestore} from "firebase/firestore";
import {app} from "../../../firebase/clientApp";
import {useNavigate} from "react-router-dom";
import {getStripePayments, getProducts} from "@stripe/firestore-stripe-payments";
import ErrorHandler from "../../../classes/errorhandler";
import {collection, onSnapshot, doc, setDoc} from "firebase/firestore";
import Loading from "../../common/Loading";
import {SubscriptionContext} from "../../../context/subscription-context";

// eslint-disable-next-line valid-jsdoc
/**
 * Billing component handles the payment functionalities of the application. It enables the user to
 * subscribe to different plans and proceed to checkout for payment.
 * It uses the context to retrieve the user's data and the user's subscription status.
 * It's designed using Material-UI for the interface and Stripe for the payments.
 * The subscription plans and their details are hardcoded into the component.
 * It also allows the user to choose between monthly and annual billing intervals.
 * The component also interacts with Firebase Firestore to create, read, and update checkout sessions.
 * In case of any error while setting up the checkout, it will display an alert with the error message.
 *
 * @module components/Billing
 *
 * @requires react
 * @requires @mui/material
 * @requires @mui/icons-material/StarBorder
 * @requires firebase/firestore
 * @requires @stripe/firestore-stripe-payments
 * @requires components/common/Loading
 * @requires classes/errorhandler
 *
 * @return {React.Component} The Billing component
 */
function Billing() {
  const {user} = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [billingInterval, setBillingInterval] = useState("month");
  const {subscriptionStatus} = useContext(SubscriptionContext);

  const tiers = [
    {
      title: "Startup",
      description: [
        "10 Posts",
        "1 Dealership",
        "3 Templates",
        "Email support",
      ],
      buttonText: subscriptionStatus ? "Upgrade" : "Get Started",
      buttonVariant: "outlined",
    },
    {
      title: "Enterprise",
      subheader: "Most popular",
      description: [
        "50 Posts a Month",
        "1 Dealership",
        "Premium Templates",
        "Priority email support",
      ],
      buttonText: subscriptionStatus ? "Upgrade" : "Get Started",
      buttonVariant: "contained",
    },
    {
      title: "Commercial",
      description: [
        "100 Posts a Month",
        "5 Dealerships",
        "Premium Templates",
        "Phone & email support",
      ],
      buttonText: subscriptionStatus ? "Upgrade" : "Get Started",
      buttonVariant: "outlined",
    },
  ];

  const startCheckout = async (priceId) => {
    if (!user || priceId === 0 ) {
      navigate("/create");
      return;
    }
    setLoadingCheckout(true);

    const sessions = collection(getFirestore(app), `customers/${user.uid}/checkout_sessions`);

    const d = doc(sessions);
    await setDoc(d, {
      price: priceId,
      success_url: `${window.location.origin}/dashboard/billing/success`,
      cancel_url: `${window.location.origin}/dashboard/billing`,
      trial_from_plan: false,
      allow_promotion_codes: true,
    });

    onSnapshot(d, (snap) =>{
      const {error, url} = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
        setLoadingCheckout(false);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        setLoadingCheckout(false);

        // ga.event({
        //     action: 'begin_checkout',
        //     params: {
        //         event_callback: function () {
        //             window.location.href = url;
        //         }}
        // })

        window.location.assign(url);
      }
    });
  };

  const getActiveProduct = (title, interval = billingInterval) => {
    const p = products[title];

    const {prices, name} = p;

    const pr = prices.filter((item) => item.active && item.interval === interval);

    // eslint-disable-next-line camelcase
    const {id, unit_amount} = pr[0];

    // eslint-disable-next-line camelcase
    let price = (unit_amount / 100).toFixed(2);

    // If billing is annual, calculate average monthly cost
    if (interval === "year") {
      price = (price / 12).toFixed(2);
    }

    return {
      id,
      price,
      name,
    };
  };

  useEffect(() => {
    setLoading(true);

    const payments = getStripePayments(app, {
      productsCollection: "products",
      customersCollection: "customers",
    });

    getProducts(payments, {
      includePrices: true,
      activeOnly: true,
    })
        .then((products) => {
          const productsObj = products.reduce((acc, product) => {
            acc[product.name] = product;
            return acc;
          }, {});
          setProducts(productsObj);
          setLoading(false);
        })
        .catch((error) => ErrorHandler.record(error));
  }, []);

  if (loading || products.length === 0) {
    return null;
  }

  return (
    <CommonContainer>
      {loadingCheckout && <Loading text="Loading Checkout" />}
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mb: 2}}>
        <ButtonGroup variant="contained">
          <Button
            onClick={() => setBillingInterval("month")}
            variant={billingInterval === "month" ? "contained" : "outlined"}
          >
                        Monthly
          </Button>
          <Button
            onClick={() => setBillingInterval("year")}
            variant={billingInterval === "year" ? "contained" : "outlined"}
          >
                        Annually
          </Button>
        </ButtonGroup>
      </Box>
      <Grid container spacing={5} alignItems="flex-end">
        {tiers.map((tier) => {
          const activeProduct = getActiveProduct(tier.title);
          // Enterprise card is full width at sm breakpoint
          return (<Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card>
              <CardHeader
                title={activeProduct.name}
                subheader={tier.subheader}
                titleTypographyProps={{align: "center"}}
                action={tier.title === "Pro" ? <StarIcon/> : null}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{
                  backgroundColor: (theme) =>
                                                theme.palette.mode === "light" ?
                                                    theme.palette.grey[200] :
                                                    theme.palette.grey[700],
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h3" color="text.primary">
                                                ${activeProduct.price}

                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                                                /mo
                  </Typography>
                </Box>
                <ul>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="left"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => startCheckout(activeProduct.id)}
                  fullWidth
                  variant={tier.buttonVariant}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          );
        },
        )}

      </Grid>
    </CommonContainer>
  );
}

export default Billing;
