import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const StyledBody = styled.div`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  position: relative;
  overflow: hidden;
  text-align: left;
  width: auto;
  //max-width: 512px;
  height: 512px;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const PriceBanner = styled.div`
  position: absolute;
  display: flex;
  top: 35px; // adjust this to move the ribbon up or down
  right: -40px; // adjust this to move the ribbon left or right
  width: 220px; // adjust this to change the width of the ribbon
  height: 50px;
  padding: 5px 15px;
  background-color: black;
  border-top: 4px solid ${(props) => props.primaryColor ? props.primaryColor : "#E90226"};
  border-bottom: 4px solid ${(props) => props.primaryColor ? props.primaryColor : "#E90226"};
  text-align: center;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg); // adjust the rotation here
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
`;

export const Price = styled.span`
  color: white;
  font-size: 22px;
  font-family: Plus Jakarta Sans, sans-serif;
  font-weight: 700;
`;

export const BlackBar = styled.div`
  background-color: black;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 5px solid ${(props) => props.primaryColor ? props.primaryColor : "#E90226"}
`;

export const YMM = styled.span`
  position: absolute;
  color: white;
  left: 15px;
  font-weight: bold;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 18px;
`;

export const StyledLogo = styled.img`
  position: absolute;
  right: 15px;
`;

export const StyledContent = styled.div`
  background-color: #B4B4B4;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
`;

export const DataTable = styled.table`
  border-collapse: collapse;
  width: 90%;
  margin: 0;

  th, td {
    border: 0;
    padding: 5px;
    text-align: left;
    font-size: 18px;
  }
`;

export const DataIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  width: 35px;
`;

export const Image = styled.img`
  object-fit: cover;
  height: 380px;
`;
