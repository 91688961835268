import React from "react";
import {Navigate} from "react-router-dom";
import {useAuth} from "../../context/auth-context";
import {Container, Paper, CircularProgress} from "@mui/material";
import PropTypes from "prop-types";

/**
 * PrivateRoute component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be rendered within the PrivateRoute component.
 * @return {JSX.Element} PrivateRoute component.
 */
const PrivateRoute = ({children}) => {
  const {user, loading} = useAuth();

  if (loading) {
    // You can return a loading spinner, a loading screen or null
    return (
      <Container maxWidth="lg" justifyContent="center">
        <Paper style={{textAlign: "center"}} sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
          <CircularProgress />
        </Paper>
      </Container>
    );
  }

  if (user) {
    return children;
  }

  return <Navigate to="/auth/login" replace />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
