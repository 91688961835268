import React, {useState, useEffect} from "react";
import {Alert, Grid, TextField, Checkbox, FormControlLabel, Button, Container, Paper} from "@mui/material";
import {useAuth} from "../../../../context/auth-context";
import {getFirestore, doc, collection, addDoc, getDoc, setDoc} from "firebase/firestore";
import {app} from "../../../../firebase/clientApp";
import {FormWrapper} from "./styles";
import {useLocation} from "react-router-dom";
import CommonContainer from "../../../common/Layout/Container";
const DealerForm = () => {
  const [dealer, setDealer] = useState({
    name: "",
    webAddress: "",
    phoneNumber: "",
    emailAddress: "",
    financingAvailable: false,
    howDidYouHear: "",
  });
  const [saved, setSaved] = useState(false);
  const {user} = useAuth();
  const db = getFirestore(app);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dealerId = searchParams.get("dealerId");

  useEffect(() => {
    if (dealerId) {
      const fetchDealer = async () => {
        const dealerDoc = doc(db, "users", user.uid, "dealers", dealerId);
        const dealerSnapshot = await getDoc(dealerDoc);
        if (dealerSnapshot.exists()) {
          setDealer(dealerSnapshot.data());
        }
      };
      fetchDealer();
    }
  }, [db, user.uid, dealerId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userRef = doc(db, "users", user.uid);
    const dealersCollection = collection(userRef, "dealers");

    if (dealerId) {
      const dealerDoc = doc(dealersCollection, dealerId);
      await setDoc(dealerDoc, dealer);
      setSaved(true);
    } else {
      await addDoc(dealersCollection, dealer);
    }
  };

  const handleChange = (event) => {
    setDealer({...dealer, [event.target.name]: event.target.value});
  };

  const handleCheckboxChange = (event) => {
    setDealer({...dealer, [event.target.name]: event.target.checked});
  };

  return (
    <CommonContainer>
      <Container maxWidth="sm">
        <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
          {saved && <Alert severity="success">Details Saved</Alert>}
          <FormWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Dealer Name" variant="outlined" fullWidth name="name" value={dealer.name} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Web Address" variant="outlined" fullWidth name="webAddress" value={dealer.webAddress} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Phone Number" variant="outlined" fullWidth name="phoneNumber" value={dealer.phoneNumber} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Email Address" variant="outlined" fullWidth name="emailAddress" value={dealer.emailAddress} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={dealer.financingAvailable} onChange={handleCheckboxChange} name="financingAvailable" />}
                  label="Financing Available"
                />
              </Grid>
              <Grid item xs={12}>
                <Button disabled={saved} type="button" variant="contained" color="primary" onClick={handleSubmit} fullWidth>Submit</Button>
              </Grid>
            </Grid>
          </FormWrapper>
        </Paper>
      </Container>
    </CommonContainer>
  );
};

export default DealerForm;
